import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';

import { NeedsAnalysis } from '../components/needs.jsx';
import { PreviousNextButtons } from '../controls/buttons.jsx';
import { RadioControl } from '../controls/radio.jsx';
import { TextControl } from '../controls/text.jsx';
import { sendLeadEmail } from '../libs/api.js';
import {
  InsuranceDataContext,
  LayoutContext,
  SessionContext,
} from '../libs/contexts.js';
import { asCurrency } from '../libs/i18n.js';
import {
  ageFromDob,
  calculateScenarios,
  getOffers,
} from '../libs/insurance.js';
import { scrollUp } from '../libs/ui.js';
import * as productQuestions from './quickterm-questions.js';

export const QuickTermQuoter = () => {

    const { layout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);

    const { insuranceData } = useContext(InsuranceDataContext);
    const [ready, setReady] = useState(false);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const [notify, setNotify] = useState(false);


    useEffect(() => {

        if (undefined !== session.phone && undefined !== session.email) {
            if (undefined === session.notified) {
                //.info("Sending notification");
                setSession({ notified: Date.now() });
                sendLeadEmail({
                    token: session.token,
                    quote: session,
                    next: (data) => {
                        //.info("Notification sent", data); 
                    }
                });

            } else {
                //.info("notification already sent");
            }
        }

    }, [session, session.phone, session.email, session.notified, session.token]);


    useEffect(() => {



        //todo refactor for multiproduct/terms

        if (insuranceData.plans && insuranceData.plans.term10) {
            if (insuranceData.plans.term10.minface) {
                setMinValue(insuranceData.plans.term10.minface);
                setReady(true);
            }
            if (insuranceData.plans.term10.maxface) {
                setMaxValue(insuranceData.plans.term10.maxface);
                setReady(true);
            }
        }

    }, [insuranceData.plans]);

    return (
        <>
            { layout && "needs" === layout.quoterView ?
                <>
                    { ready &&
                        <NeedsAnalysis minFacevalue={ minValue } maxFacevalue={ maxValue } />
                    }
                </>
                :
                <MainQuoter />
            }
        </>
    );

}

const MainQuoter = () => {


    const { session, setSession } = useContext(SessionContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [genders, setGenders] = useState({});
    const [smoking, setSmoking] = useState({});
    const [minage, setMinage] = useState();
    const [maxage, setMaxage] = useState();
    const [minface, setMinface] = useState();
    const [maxface, setMaxface] = useState();

    useEffect(() => {

        scrollUp();

        let newGenders = {
            "M": "Male",
            "F": "Female"
        };
        setGenders(newGenders);

        let newSmoking = {
            No: "None",
            Yes: "Daily/Weekly",
            Maybe: "On occasion"
        };
        setSmoking(newSmoking);

        // let requirements = { ...layout.requirements };
        // requirements.quoter = "pass";

        // let newLayout = {
        //     requirements: requirements
        // };

        setLayout({ currentScreen: "quoter" });



    }, []);

    useEffect(() => {

        if (insuranceData) {
            if (insuranceData.plans) {
                if (insuranceData.plans.term10) {  // the limits are (should be?) the same
                    if (insuranceData.plans.term10.maxage && insuranceData.plans.term10.minage) {

                        if (insuranceData.plans.term10.maxface && insuranceData.plans.term10.minface) {

                            let newMaxage = parseInt(insuranceData.plans.term10.maxage.toString());
                            let newMinage = parseInt(insuranceData.plans.term10.minage.toString());
                            let newMaxface = parseInt(insuranceData.plans.term10.maxface.toString());
                            let newMinface = parseInt(insuranceData.plans.term10.minface.toString());
                            if (!isNaN(newMaxage) && !isNaN(newMinage) && !isNaN(newMaxface) && !isNaN(newMinface)) {
                                setMaxage(newMaxage);
                                setMaxface(newMaxface);
                                setMinage(newMinage);
                                setMinface(newMinface);
                            }
                        }

                    }


                }
            }
            console.log("Insurance data", insuranceData);
        }

    }, [insuranceData]);



    const onChange = (e) => {

        switch (e.id) {
            case "dob":

                if (e.value && 10 === e.value.length) {
                    let age = ageFromDob({ dobStr: e.value });
                    setSession({ age: age });
                } else {
                    setSession({ age: null });
                }
                break;
            case "smoking_general":
                let newSessionData = {};
                if (["Yes", "No"].includes(e.value)) {
                    newSessionData.smoking_specific = "n/a";
                    if ("Yes" === e.value) {
                        newSessionData.smoking = "1";
                        newSessionData["question_" + productQuestions.smoking.id] = {
                            "id": productQuestions.smoking.yes,
                            "text": "Yes"
                        };
                    } else {
                        newSessionData.smoking = "0";
                        newSessionData["question_" + productQuestions.smoking.id] = {
                            "id": productQuestions.smoking.no,
                            "text": "No"
                        };
                    }
                    setSession(newSessionData);
                } else {
                    newSessionData.smoking = null;
                    newSessionData["question_" + productQuestions.smoking.id] = {
                        "id": productQuestions.smoking.maybe.id,
                        "text": "Occasional"
                    };

                }
                setSession(newSessionData);
                break;
            case "smoking_specific":
                if (["Yes", "No"].includes(e.value)) {
                    // be careful, a "Yes" in this case means this is NOT a smoker (due to negative in question)
                    let newSessionData = {}
                    if ("Yes" === e.value) {
                        newSessionData.smoking = "0";
                        newSessionData["A" + productQuestions.smoking.maybe.yes] = "Yes";
                        newSessionData["question_" + productQuestions.smoking.id] = {
                            "id": productQuestions.smoking.maybe.yes,
                            "text": "Yes"
                        };
                    } else {
                        newSessionData.smoking = "1";
                        newSessionData["A" + productQuestions.smoking.maybe.no] = "No";
                        newSessionData["question_" + productQuestions.smoking.id] = {
                            "id": productQuestions.smoking.maybe.no,
                            "text": "No"
                        };
                    }
                    setSession(newSessionData);
                }
                break;
        }

        validateScreen();
    }

    const validateScreen = () => {


    }

    const showAnalysis = (e) => {

        setLayout({ quoterView: "needs" });

        e.preventDefault();

    }

    const showDebug = (e) => {
        /*
        console.clear();
        console.log("Current offers:", session.offers);
        console.log("Current term:", session.term_selected);
        console.log("Current age:", session.age);
        console.log("All plans:", insuranceData.plans);
        console.log("Term10 rates:", insuranceData.term10);
        console.log("Term20 rates:", insuranceData.term20);
        console.log("Rider10 rates:", insuranceData.rider10);
        console.log("Rider20 rates:", insuranceData.rider20);
        */
    }

    return (
        <>
            <Form>
                <Container>
                    <Row className="mt-4 mb-4">
                        <Col>
                            <h3>Get your <span className="text-primary">instant</span> life insurance quote</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-lg-12 col-xl-10">
                            <TextControl label="What is date of birth?" title="Please provide us with your date of birth" field="dob" placeholder="yyyy-mm-dd" hasErrors={ false } onChange={ onChange } required={ true } type="dob" />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col key="gender" className="col-lg-12 col-xl-10">
                            <RadioControl key="gender" label="What is your gender?" choices={ genders } type="horizontal" onChange={ onChange } field="gender" />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col key="smoking" className="col-lg-12 col-xl-10">
                            <RadioControl key="smoking" label="Use of nicotine or tobacco products in the last 12 months?" choices={ smoking } type="horizontal" onChange={ onChange } field="smoking_general" helpMessage="The following are considered nicotine or tobacco products: using cigarettes, cigarillos, colts, cigars, pipes, chewing tobacco, snuff, e-cigarettes, vaporizers, nicotine gum or patches of any other form of nicotine substitute. Does not include cannabis." />
                        </Col>
                    </Row>

                    { "Maybe" === session.smoking_general &&
                        <Row>
                            <Col key="smoking" className="col-lg-12 col-xl-10">
                                <RadioControl key="smoker" label="In the last 12 months have you ONLY smoked large cigars (12 or less throughout the year)?" type="yesno" onChange={ onChange } field="smoking_specific" helpMessage="Does not include cannabis" />
                            </Col>
                        </Row>
                    }

                    { maxage && minage && maxface && minface &&
                        <Row>
                            <Col className="col-lg-12 col-xl-10">
                                <TextControl helpMessage={ "Must be between " + asCurrency({ amount: minface }) + " and " + asCurrency({ amount: maxface }) + "." } minValue={ minface } maxValue={ maxface } label="Desired policy value" title="Please provide us with the desired value" field="facevalue" hasErrors={ false } onChange={ onChange } required={ true } type="dollar" />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col className="col-lg-12 col-xl-10">
                            <a onClick={ showAnalysis } href="#">Not sure how much coverage you need?</a>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col className="col-lg-12 col-xl-10">
                            <TermSelector onChange={ onChange } />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="col-lg-12 col-xl-10">
                            <RiderSelector onChange={ onChange } />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="col-lg-12 col-xl-10">
                            <PremiumLine />
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col className="col-lg-12 col-xl-10">
                            <ContinueLine />
                        </Col>
                    </Row>

                </Container>
            </Form>
        </>
    )

}

const TermSelector = ({ onChange }) => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { session, setSession } = useContext(SessionContext);
    const [display, setDisplay] = useState(false);
    const [terms, setTerms] = useState({});



    useEffect(() => {

        const undoOffers = (newDisplay) => {
            if (undefined === newDisplay) {
                newDisplay = false;
            }
            setSession({
                offers: false,
                premium: null,
                rider: null
            });
            setDisplay(newDisplay);
        }

        if (undefined !== session.age && undefined !== session.gender && undefined !== session.smoking && null !== session.smoking && undefined !== session.facevalue) {

            if (undefined !== insuranceData.plans) {

                if ("" !== session.age && session.age > 0 && "" !== session.facevalue && session.facevalue > 0 && "" !== session.gender) {
                    let result = getOffers({ age: session.age, gender: session.gender, smoker: session.smoking, facevalue: session.facevalue, insuranceData: insuranceData });

                    let newTerms = {};

                    if (result.offers) {

                        if (result.eligibility && result.eligibility.age && result.eligibility.facevalue) {

                            for (let [term, offer] of Object.entries(result.offers)) {
                                let years = term.replace("term", "");
                                newTerms[term] = asCurrency({ amount: offer.base }) + " per month. Price guaranteed for " + years + " years.";
                            }
                            setSession(result);
                            setTerms(newTerms);
                            setDisplay('terms');


                        } else {

                            undoOffers('exception');
                        }

                    } else {
                        if (result.segment) {
                            undoOffers('exception');
                        } else {
                            undoOffers();
                        }


                    }
                } else {
                    undoOffers();
                }


            } else {
                undoOffers();
            }

        } else {
            setDisplay(false);
        }

    }, [session.age, session.gender, session.smoking, session.facevalue, insuranceData.plans]);


    return (<>
        { 'terms' === display ?
            <RadioControl key="term" label="Which option suits you best?" choices={ terms } type="horizontal" onChange={ onChange } field="term_selected" helpMessage="You can remain insured at the end of 10 or 20-year term period but your monthly premiums will likely increase annually." />
            :
            <>
                { 'exception' === display ?
                    <><DisqualifiedMessage /></>
                    :
                    <></>
                }
            </>
        }

    </>);

}

const DisqualifiedMessage = () => {

    const { layout } = useContext(LayoutContext);

    return (
        <>
            <p>
                We have products that are better suited to your needs. Please contact us at { layout.phone } or { layout.email }
            </p>
        </>
    );
}


const RiderSelector = ({ onChange }) => {

    const { session } = useContext(SessionContext);
    const [ready, setReady] = useState(false);
    const [waiver, setWaiver] = useState("");

    useEffect(() => {


        if (undefined !== session.offers) {

            if (session.offers) {
                let hasWaiver = false;
                //todo refactor in case there's different riders
                if (undefined !== session.term_selected) {
                    if (undefined !== session.offers && undefined !== session.offers[session.term_selected]) {
                        let newWaiver = "Add total disability waiver for " + asCurrency({ amount: session.offers[session.term_selected].rider }) + " per month?";
                        setWaiver(newWaiver);
                        hasWaiver = true;
                        setReady(hasWaiver);
                    }
                }
            } else {
                setReady(false);
            }
        }

    }, [session.offers, session.term_selected]);


    return (<>
        { ready &&
            <RadioControl key="rider" label={ waiver } type="yesno" onChange={ onChange } field="rider" helpMessage="A waiver of premium for total disability is an optional benefit provision that is designed to waive the premium if the life insured becomes totally disabled prior to attaining age 60 and remains disabled for 4 consecutive months." />
        }

    </>);

}

const PremiumLine = () => {

    const { session, setSession } = useContext(SessionContext);
    const { setLayout } = useContext(LayoutContext);
    const [ready, setReady] = useState(false);

    useEffect(() => {

        if (session.offers && session.term_selected) {

            let premiumOptions = calculateScenarios({ currentTerm: session.term_selected, rider: session.rider, offers: session.offers, facevalue: session.facevalue });
            if (undefined !== premiumOptions) {
                setSession(premiumOptions);
                setReady(true);
                setLayout({
                    screenReady: true
                });
            } else {
                setReady(false);
                setLayout({
                    screenReady: false
                });
            }

        } else {
            setReady(false);
        }


    }, [session.rider, session.term_selected, session.offers]);

    useEffect(() => {

        if (session.premium && session.premium.includes('$')) {
            setReady(true);
        } else {
            setReady(false);
        }

    }, [session.premium]);

    return (
        <>
            { ready &&
                <>
                    Your premium will be <b>{ session.premium }</b> per month and won't increase for { session.term_selected ? session.term_selected.replace('term', '') : 'many' } years.

                </>
            }
        </>
    );

}


const ContinueLine = () => {

    const { session } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    const [ready, setReady] = useState(false);

    useEffect(() => {

        if (session.offers && session.term_selected && session.premium && layout.screenReady) {

            setReady(true);

        } else {
            setReady(false);
        }


    }, [session.rider, session.term_selected, session.offers, session.premium, layout.screenReady]);

    return (
        <>
            { ready &&
                <>

                    { ready &&
                        <PreviousNextButtons enabled={ ready } />
                    }



                </>
            }
        </>
    );

}
