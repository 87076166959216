import { useState } from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

export const JumperLayout = ({ MyOutlet }) => {

    let [layout] = useState({
        sidebar: 'no'
    });

    return (
        <Container>
            <Row>
                <Col>
                    <JumperDesktopHeader />
                </Col>
            </Row>
            <Row>
                <Col>
                    <MyOutlet></MyOutlet>
                </Col>
            </Row>
            <Row>
                <Col>
                    <JumperDesktopFooter />
                </Col>
                {"yes" === layout.sidebar &&
                    <JumperSidebar />
                }
            </Row>
        </Container>
    )

}

export const JumperSidebar = () => {

    return (
        <>jumper sidebar</>
    )

}

export const JumperDesktopFooter = () => {
    return (
        <>
            <hr />
            <p>&copy; Wawanesa <small>jumper layout</small></p>
        </>
    )
}

export const JumperDesktopHeader = () => {

    return (
        <>
            <h1>QuickApply <small>jumper layout</small></h1>
        </>
    )

}

export const JumperMobileFooter = () => {
    return (
        <>
            <hr />
            <p>&copy; Wawanesa</p>
        </>
    )
}

export const JumperMobileHeader = () => {

    return (
        <></>
    )

}

