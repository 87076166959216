import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Card,Image,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import {
    BsEmojiHeartEyes,
    BsExclamationSquare,
} from 'react-icons/bs';
import { RiCustomerServiceLine } from 'react-icons/ri';
import * as config from '../config.js';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { TextControl } from '../controls/text';

const CommentForm = () => {

    const { session, setSession } = useContext(SessionContext);
    const [updated, setUpdated] = useState(false);
    const [hasSaved, setHasSaved] = useState(false);

    const onChange = ({e}) => {
        setHasSaved(false);
        setUpdated(true);        
    }

    useEffect(()=>{
        if(updated){
            setTimeout(()=>{
                setHasSaved(true);
                setUpdated(false);
            },5000);        
        }
    },[updated]);

    return(<>
        <Container fluid>
        <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Questions or comments?" title="Questions or comments?" field="comments" placeholder="Questions or comments" onChange={onChange} required={true} type="notes" />
                        {hasSaved &&
                        <p className="text-muted float-end"><small>Your message has been saved</small></p>
                        }
                    </Col>
                </Row>
        </Container>
    
    </>)
}

const Ending = ({screen, message}) => {

    const { layout, setLayout } = useContext(LayoutContext);

    useEffect(() => {

        setLayout({ currentScreen: screen });

    }, []);

    return(
    
    <Card>
        <Card.Body>
            <Container fluid>
                <Row>                    
                    <Col>
                        <Card.Title>Exciting news!</Card.Title>
                        <Card.Text>
                            {message}
                        </Card.Text>
                        <Card.Text>
                            {layout.phone} or {layout.email}
                        </Card.Text>
                        <CommentForm />
                    </Col>
                    <Col className='col-md-4'>
                        <Image src={config.assets + "/business-options-5.jpg"} fluid />
                    </Col>
                </Row>                
            </Container>

        </Card.Body>
    </Card>
    );

}

export const ProductMismatch = () => {

    
    return (
        <>
            <Ending message="There are products that are a better fit for you. Let's talk!" screen="opportunities" />
        </>
    );

}


export const UnqualifiedLead = () => {
  
    return (
        <>
            <Ending message="There are products that are a better fit for you. Let's talk!" screen="thankyou" />
        </>
    );

 

}