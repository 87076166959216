import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Badge,
    Card,
    Col,
    Container,
    Image,
    ProgressBar,
    Row,
} from 'react-bootstrap';

import * as config from '../config.js';
import { SideBarMedia } from '../controls/sidebar-media.jsx';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts.js';
import {
    BaseDesktopFaq,
    BaseDesktopFaqTrigger,
    BaseDesktopFooter,
    BaseDesktopHeader,
    BaseDesktopMenu,
    BaseDesktopPremiumBox,
    BaseDesktopProgressBar,
    BaseDesktopSidebar,
} from './base-desktop.jsx';

export const BohemiaDesktopLayout = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);

    return (

        <>
            <div className="content-container">
                <BohemiaDesktopMenu />
                <Container fluid>
                    <Row>
                        <Col className="desktop-area">
                            <Container fluid>
                                <Row>
                                    <Col className={ "yes" === layout.iframeVisible ? "offset-xl-1" : "offset-lg-2 offset-xl-3" }>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    <BohemiaDesktopHeader />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    { "yes" === layout.faq ?
                                                        <BohemiaDesktopFaq />
                                                        :
                                                        <>
                                                            { "jump" === layout.specialView ?
                                                                <p>We are getting your quote ready, please hold...</p>
                                                                :
                                                                <>
                                                                    { "steady" === branding.progressMode &&
                                                                        <BohemiaDesktopProgressBar />
                                                                    }
                                                                    <MyOutlet></MyOutlet>
                                                                </>
                                                            }</>

                                                    }

                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    { "yes" !== layout.iframeVisible &&
                                        <Col className="col-lg-4 col-md-5 col-xl-3">
                                            { "jump" !== layout.specialView && !["thankyou", "opportunities", "consent", "end"].includes(layout.currentScreen) &&
                                                <BohemiaDesktopSidebar />
                                            }
                                        </Col>
                                    }
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            { "end" !== layout.currentScreen &&
                <BohemiaDesktopFooter />
            }

        </>
    )


}

export const BohemiaDesktopFaq = () => {

    return (
        <>
            <BaseDesktopFaq />
        </>
    );
}

export const BohemiaDesktopFooter = () => {

    return (
        <>
            <BaseDesktopFooter />
        </>
    );
}

export const BohemiaDesktopHeader = () => {

    return (
        <>
        </>
    );
}


export const BohemiaDesktopMenu = () => {

    const { layout } = useContext(LayoutContext);
    const { session } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext);

    return (
        <Container fluid className="menu">
            <Row>
                <Col>
                    <Container fluid className="menu">
                        <Row className="mb-1 mt-2">
                            <Col className='col-md-7 offset-md-1 desktop-menu'>
                                <a target="_blank" href={ branding.website } rel="noopener noreferrer">
                                    <Image className="broker-logo" src={ config.assets + '/logo.png' } />
                                </a>
                            </Col>
                            <Col className='col-md-4 desktop-menu text-end'>
                                <a href={ `mailto:${layout.email}` }>
                                    <Badge className="menu-item mb-0 mt-1 clicker">
                                        <h5 className="mt-1">
                                            { layout.email }
                                        </h5>
                                    </Badge>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className="mt-0">
                <Col className="px-0 mx-0">
                    { "jump" !== layout.specialView && !['policy', 'thankyou', 'opportunities'].includes(layout.currentScreen) &&
                        <>
                            <BohemiaDesktopProgressBar />
                        </>
                    }
                </Col>
            </Row>
        </Container>

    );

}

export const BohemiaDesktopProgressBar = () => {

    const { layout } = useContext(LayoutContext);
    const [progress, setProgress] = useState();
    const [color, setColor] = useState('primary');
    const [isVisible, setIsVisible] = useState(true);

    const { branding } = useContext(BrandingContext);

    useEffect(() => {

        if (layout.currentScreen && layout.screenSequence) {
            let screenCount = layout.screenSequence.length;
            let screenIndex = 0;
            for (let i = 0; i < screenCount; i++) {
                if (layout.currentScreen === layout.screenSequence[i]) {
                    screenIndex = i + 1;
                }
            }
            let screenDiv = screenIndex / (screenCount);
            screenDiv = Math.ceil(100 * screenDiv);
            screenDiv = Math.round(screenDiv / 10) * 10;
            if (screenDiv > 90) {
                screenDiv = 95;
            }
            let newProgress = screenDiv;
            setProgress(newProgress);
        }

    }, [layout.currentScreen, layout.screenSequence]);

    useEffect(() => {

        if ((layout.quoterView && "needs" === layout.quoterView) || 'policy' === layout.currentScreen || 'consent' === layout.currentScreen) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }

    }, [layout.quoterView, layout.currentScreen]);


    useEffect(() => {


        if (progress <= 10) {
            setColor('primary');
        } else {
            if (progress > 10 && progress <= 40) {
                setColor('info');
            } else {
                if (progress > 40 && progress <= 80) {
                    setColor('warning');
                } else {
                    setColor('success');
                }
            }
        }

    }, [progress]);

    return (
        <>
            { isVisible && progress && progress >= 1 &&
                <>
                    <ProgressBar className="full-square" title="If you complete the process, you will be insured in a matter of minutes" animated variant={ color } now={ progress } label={ progress >= 20 ? `${progress}%` : `${progress}%` } />
                </>

            }
        </>
    )

}

export const BohemiaDesktopSidebar = () => {

    const { branding } = useContext(BrandingContext);
    const { layout } = useContext(LayoutContext);


    return (
        <Container fluid>
            <Row>
                <Col>
                    <BaseDesktopPremiumBox />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>

                    <Card className="mt-4 border-0">
                        <div className="d-none d-lg-block d-xl-block d-xxl-block mx-auto">
                            <SideBarMedia />
                        </div>
                        <blockquote className="blockquote text-center text-muted fst-italic">
                            <p>{ branding.tagline }</p>
                        </blockquote>

                        <Card.Body className="px-0">
                            <Container fluid>
                                <Row className='mb-2'>
                                    <Col className="text-center">
                                        { layout.phone }
                                    </Col>
                                </Row>
                                <Row className='text-center'>
                                    <Col className='px-0'>
                                        <BaseDesktopFaqTrigger />
                                    </Col>
                                </Row>

                            </Container>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Container>
    );

}
