import {
    useContext,
    useEffect,
    useState,
} from 'react';

import { Form } from 'react-bootstrap';

import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts';
import * as settings from '../products/quickterm-settings';

//jurisdictions
export const ProvinceSelect = ({ field, placeholder, mustRevalidate, onChange }) => {

    const { session, setSession } = useContext(SessionContext);
    const [hasErrors, setHasErrors] = useState(false);


    useEffect(() => {

        if (session[field]) {
            //.info("We have existing value", field, session[field]);
        }

    }, []);



    useEffect(() => {

        if (mustRevalidate) {
            validateField();
        }

    }, [mustRevalidate]);



    const hasChanged = (e) => {
        //.log("Saving province", e.target.value);
        let newSessionData = {};
        newSessionData[field] = e.target.value;
        setSession(newSessionData);
        validateField();
    }

    const validateField = () => {


        onChange({ id: field, value: session[field] });

    }

    return (
        <>

            <div className={hasErrors ? "text-danger font-italic mb-3" : "mb-3"}>

                {settings.jurisdictions &&
                    <>
                        {placeholder &&
                            <Form.Label>{placeholder}
                            </Form.Label>
                        }
                        <Form.Select aria-label={placeholder} id={field} value={session[field] || ""} onChange={hasChanged}>
                            <option key={field + "-" + placeholder} disabled>{placeholder}</option>
                            {settings.jurisdictions.provinces.map((province, index) =>
                                <option
                                    key={province.statecode}
                                    value={province.statecode}
                                    // selected={value === province.statecode}
                                    //onClick={() => hasChanged(province.statecode)}
                                    aria-label={`${province.statecode}`}
                                >{province.name}</option>
                            )}
                            <option
                                key="X"
                                value="X"
                                // selected={value === "X"}
                                //onClick={() => hasChanged("X")}
                                aria-label={"X"}
                            >(Somewhere outside Canada)</option>
                        </Form.Select>
                    </>
                }

            </div>

        </>
    )

}



export const SimpleSelect = ({ field, choices, placeholder, expectedValue, mustRevalidate, onChange, helpMessage }) => {

    const { session, setSession } = useContext(SessionContext);
    const [hasErrors, setHasErrors] = useState(false);
    const [value, setValue] = useState("");

    const [options, setOptions] = useState(false);

    useEffect(() => {

        if (session[field]) {
            setValue(session[field]);
        }


    }, []);

    useEffect(() => {

        if (choices) {
            if (choices instanceof Map || choices instanceof Object) {
                let newOptions = [];
                if (choices instanceof Map) {
                    // we use a map when we need sorted objects
                    for (const [key, item] of choices.entries()) {
                        let newOption = {
                            id: key,
                            copy: item.copy
                        };
                        newOptions.push(newOption);
                    }
                }
                else {
                    // we use an object when we need simple k/v pairs
                    for (const [key, value] of Object.entries(choices)) {
                        let newOption = {
                            id: key,
                            copy: value
                        };
                        newOption[key] = value;
                        newOptions.push(newOption);
                    }
                }
                setOptions(newOptions);
            }
        }

    }, [choices]);


    useEffect(() => {

        if (mustRevalidate) {
            if (session[field]) {
                setValue(session[field]);
            }
            validateField();
        }

    }, [mustRevalidate]);



    const hasChanged = (e) => {

        setValue(e);
        validateField();

    }

    const validateField = () => {

        let canUpdate = false;
        if (undefined !== expectedValue) {
            if (value !== expectedValue) {
                setHasErrors(true);
            } else {
                setHasErrors(false);
                canUpdate = true;
            }
        } else {
            canUpdate = true;
        }

        onChange({ id: field, isValid: !hasErrors });

        if (canUpdate) {
            let newSession = {};
            newSession[field] = value;
            setSession(newSession);
        }
    }

    return (
        <>

            <div className={hasErrors ? "text-danger font-italic mb-3" : "mb-3"}>

                {options &&
                    <>
                        <Form.Select aria-label={placeholder} id={field}>
                            <option key={field + "-" + placeholder} disabled>{placeholder}</option>
                            {options.map((option, index) =>

                                <option
                                    value={option.id}
                                    selected={value === option.id}
                                    onClick={() => hasChanged(option.id)}
                                    onChange={() => hasChanged(option.id)}
                                    aria-label={`${option.id}`}
                                >{option.copy}</option>

                            )}
                        </Form.Select>
                    </>
                }

            </div>

        </>
    )

}


