import { Card } from 'react-bootstrap';

import * as config from '../config.js';

export const EjectionScreen = () => {

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>End of the road</Card.Title>
                    <Card.Text>
                        Unfortunately this is as far as this demo goes.
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );

}
