import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { TextControl } from '../controls/text';
import { ToggleAccordion } from '../controls/wizards';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
  stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import * as productQuestions from '../products/quickterm-questions';

export const Diagnosis = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [parentQuestion, setParentQuestion] = useState(false);
    const [occupations, setOccupations] = useState(false);
    const [outcome, setOutcome] = useState("incomplete");

    const [questions, setQuestions] = useState([]);
    const [heading, setHeading] = useState("");
    const [helpVisible, setHelpVisible] = useState(false);


    const [clicked, setClicked] = useState(0);


    const [viewMode, setViewMode] = useState("accordion");

    const [ready, setReady] = useState(false);



    const [updated, setUpdated] = useState(false);



    useEffect(() => {

        scrollUp();
        setLayout({
            screenReady: false,
            currentScreen: 'diagnosis'
        });

    }, []);


    useEffect(() => {

        if ('completed' === outcome) {
            setLayout({ screenReady: true });
        }


    }, [outcome]);

    const validateScreen = () => {


    }


    const onChange = () => {


    }

    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h5>{productQuestions.headings.diagnosis}</h5>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <ToggleAccordion questions={productQuestions.diagnosis} setOutcome={setOutcome} />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
