import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import * as productQuestions from '../products/quickterm-questions';

export const Introduction = () => {

    const { session, setSession } = useContext(SessionContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const [smoking, setSmoking] = useState({});
    const [mustRevalidate, setMustRevalidate] = useState(false);
    const [purposes, setPurposes] = useState({});


    useEffect(() => {

        scrollUp();
        setPurposes({
            "Business": "Business",
            "Personal": "Personal"
        });
        setLayout({ currentScreen: 'introduction', screenReady: false });

    }, []);

    const onChange = (e) => {
        validateScreen();
    }

    const validateScreen = () => {

        setMustRevalidate(true);
    }

    return (
        <>
            <Form>
                <Container>
                    <Row className="mt-4 mb-4">
                        <Col>
                            <h3>General information.</h3>
                        </Col>
                    </Row>
                    {productQuestions.introduction.map((question, index) =>
                        <Row className="mt-2" key={question.id}>
                            <Col>
                                <RadioControl mustRevalidate={mustRevalidate} label={question.copy} type="yesno" onChange={onChange} field={"A" + question.id} />
                            </Col>
                        </Row>
                    )}
                    <Row className="mt-2">
                        <Col>
                            <RadioControl key="purpose" label="What is the purpose of this policy?" choices={purposes} type="horizontal" onChange={onChange} field="purpose" />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            {layout &&
                                <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                            }
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    )

}

