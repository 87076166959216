import { useContext } from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
} from '../libs/contexts.js';
import {
  BaseMobileFaq,
  BaseMobileFooter,
  BaseMobileHeader,
  BaseMobileMenu,
  BaseMobileProgressBar,
  BaseMobileSidebar,
} from './base-mobile.jsx';

export const ModernMobileLayout = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);


    return (

        <>
            <div className="content-container">
                <ModernMobileMenu />
                <Container fluid>
                    <Row>
                        <Col className="desktop-area px-0">
                            <Container fluid>
                                <Row>
                                    <Col className='px-0'>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    <ModernMobileHeader />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {"yes" === layout.faq ?
                                                        <ModernMobileFaq />
                                                        :
                                                        <>
                                                            {"jump" === layout.specialView ?
                                                                <p>We are getting your quote ready, please hold...</p>
                                                                :
                                                                <MyOutlet></MyOutlet>
                                                            }</>

                                                    }

                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            {"end" !== layout.currentScreen &&
                <ModernMobileFooter />
            }


        </>
    )


}

export const ModernMobileFooter = () => {

    return (
        <>
            <BaseMobileFooter />
        </>
    );
}

export const ModernMobileHeader = () => {

    return (
        <>
            <BaseMobileHeader />
        </>
    )

}

export const ModernMobileMenu = () => {
    return (
        <BaseMobileMenu />
    )
}

export const ModernMobileProgressBar = () => {

    return (
        <BaseMobileProgressBar />
    )

}

export const ModernMobileSidebar = () => {

    return (
        <BaseMobileSidebar />
    )

}
