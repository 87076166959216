import 'bootstrap';

import * as React from 'react';

import * as ReactDOM from 'react-dom/client';
import * as config from './config.js';
import { LinkShopper } from './app';

let params = new URLSearchParams(window.location.search);
let entries = params.entries();
let query = Object.fromEntries(entries);
let isDesktop = !(window.matchMedia("(max-width: 480px)").matches);
//onsole.info("Starting up");

//attach favicon
let favicon = document.createElement('link');
favicon.setAttribute('rel', 'stylesheetshortcut icon');
favicon.setAttribute('href', config.assets + '/favicon.png');
document.head.appendChild(favicon);

ReactDOM.createRoot(document.getElementById("app")).render(
    <LinkShopper isDesktop={isDesktop} query={query} />
);
