export const mismatch = {
    "yes": ["A4297", "A4299", "A4314", "A4317", "A4318", "A4319",
        "A6682", "A6702", "A6704", "A6706", "A6708", "A6731", "A6729",
        "A6727", "A6725", "A6723", "A6725", "A6727", "A6729", "A6731", "A6713",
        "A6715", "A6717", "A6719", "A6721", "A6723", "A6725", "A6727", "A6729",
        "A6731", "A6663", "A6657", "A6659", "A6667", "A6665", "A6655", "A6661",
        "A4304", "A4352", "A4353", "A4354", "A4355"
    ],
    "no": [],
    "value": {
        "A4350": ["6741"],
        "A4351": ["6746"],
        "physical": "fail"
    }
};

export const unqualified = {
    "yes": ["A4315"],
    "no": ["A4298", "A4303"],
    "value": {
        "province_current": ["X"]
    }
};

