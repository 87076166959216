import convert from 'convert-units';

export const toFeet = ({ cm }) => {

    let preciseFt = convert(cm).from('cm').to('ft-us');
    let feet = Math.floor(preciseFt);
    let inches = Math.round(convert(preciseFt % 1).from('ft').to('in'));
    return { feet: feet, inches: inches };

}

export const toLbs = ({ kg }) => {

    let preciseWeight = convert(kg).from('kg').to('lb');
    return Math.round(preciseWeight);

}



export const toCm = ({ feet, inches }) => {

    let heights = [
        convert(feet).from('ft').to('cm'),
        convert(inches).from('in').to('cm')
    ];
    let cm = Math.round(heights[0] + heights[1]);
    return cm;

}

export const toKg = ({ lbs }) => {

    let preciseWeight = convert(lbs).from('lb').to('kg');
    return Math.round(preciseWeight);

}
