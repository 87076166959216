import { useContext } from 'react';

import {
    Col,
    Container,
    Row,
} from 'react-bootstrap';

import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts.js';
import {
    BaseMobileFaq,
    BaseMobileFooter,
    BaseMobileHeader,
    BaseMobileMenu,
    BaseMobileProgressBar,
    BaseMobileSidebar,
} from './base-mobile.jsx';

export const IframeMobileLayout = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);


    return (

        <>
            <div className="content-container">
                <Container fluid>
                    <Row>
                        <Col className="desktop-area px-0">
                            <Container fluid>
                                <Row>
                                    <Col className='px-0'>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    { "yes" === layout.faq ?
                                                        <IframeMobileFaq />
                                                        :
                                                        <>
                                                            { "jump" === layout.specialView ?
                                                                <p>We are getting your quote ready, please hold...</p>
                                                                :
                                                                <MyOutlet></MyOutlet>
                                                            }</>

                                                    }

                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )


}

