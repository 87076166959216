import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Col,
    Container,
    ProgressBar,
    Row,
} from 'react-bootstrap';

import { SideBarMedia } from '../controls/sidebar-media.jsx';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts.js';
import {
    BaseDesktopFaq,
    BaseDesktopFaqTrigger,
    BaseDesktopFooter,
    BaseDesktopHeader,
    BaseDesktopMenu,
    BaseDesktopPremiumBox,
    BaseDesktopProgressBar,
    BaseDesktopSidebar,
} from './base-desktop.jsx';

export const IframeDesktopLayout = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);
    const { session } = useContext(SessionContext);

    return (

        <>
            <Container>
                { "yes" === layout.faq ?
                    <Row>
                        <Col>
                            <IframeDesktopFaq />
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col className={ "yes" === layout.iframeVisible ? "col-sm-12 col-md-12 col-xl-10 offset-xl-1" : "col-sm-12 col-md-12 col-lg-8 col-xl-6 offset-xl-3" }>
                            <MyOutlet></MyOutlet>
                        </Col>
                        { "yes" !== layout.iframeVisible &&
                            <Col className="d-none d-sm-none d-md-none d-lg-block col-lg-4 col-xl-3">
                                { "jump" !== layout.specialView && !["thankyou", "opportunities", "consent", "end"].includes(layout.currentScreen) &&
                                    <IframeDesktopSidebar />
                                }
                            </Col>
                        }
                        <Col className="d-none d-sm-block d-md-block d-lg-none d-xl-gone mt-4 mb-3">
                            { session.premium_coverage &&
                                <small>
                                    This is a <b>{ session.premium_coverage }</b> policy
                                </small>
                            }
                        </Col>
                    </Row>
                }
            </Container>

        </>
    )


}

export const IframeDesktopFaq = () => {

    return (
        <>
            <BaseDesktopFaq />
        </>
    );
}

export const IframeDesktopFooter = () => {

    return (
        <>
            <BaseDesktopFooter />
        </>
    );
}


export const IframeDesktopProgressBar = () => {

    const { layout } = useContext(LayoutContext);
    const [progress, setProgress] = useState();
    const [color, setColor] = useState('primary');
    const [isVisible, setIsVisible] = useState(true);

    const { branding } = useContext(BrandingContext);

    useEffect(() => {

        if (layout.currentScreen && layout.screenSequence) {
            let screenCount = layout.screenSequence.length;
            let screenIndex = 0;
            for (let i = 0; i < screenCount; i++) {
                if (layout.currentScreen === layout.screenSequence[i]) {
                    screenIndex = i + 1;
                }
            }
            let screenDiv = screenIndex / (screenCount);
            screenDiv = Math.ceil(100 * screenDiv);
            screenDiv = Math.round(screenDiv / 10) * 10;
            if (screenDiv > 90) {
                screenDiv = 95;
            }
            let newProgress = screenDiv;
            setProgress(newProgress);
        }

    }, [layout.currentScreen, layout.screenSequence]);

    useEffect(() => {

        if ((layout.quoterView && "needs" === layout.quoterView) || 'policy' === layout.currentScreen || 'consent' === layout.currentScreen) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }

    }, [layout.quoterView, layout.currentScreen]);


    useEffect(() => {


        if (progress <= 10) {
            setColor('primary');
        } else {
            if (progress > 10 && progress <= 40) {
                setColor('info');
            } else {
                if (progress > 40 && progress <= 80) {
                    setColor('warning');
                } else {
                    setColor('success');
                }
            }
        }

    }, [progress]);

    return (
        <>
            { isVisible && progress && progress >= 1 &&
                <>
                    <ProgressBar className="full-square" title="If you complete the process, you will be insured in a matter of minutes" animated variant={ color } now={ progress } label={ progress >= 20 ? `${progress}%` : `${progress}%` } />
                </>

            }
        </>
    )

}

export const IframeDesktopSidebar = () => {

    const { branding } = useContext(BrandingContext);
    const { layout } = useContext(LayoutContext);


    return (
        <Container fluid>
            <Row>
                <Col>
                    <BaseDesktopPremiumBox />
                </Col>
            </Row>
        </Container>
    );

}

