import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import { TextControl } from '../controls/text';
import { sendEmailVerification } from '../libs/api';
import {
    asPhoneNumber,
    isEmail,
} from '../libs/contact';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';

export const EmailValidationBox = ({ setEmailOk }) => {

    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [optout, setOptout] = useState(false);
    const [editing, setEditing] = useState(false);
    const [code, setCode] = useState("");
    const [allowResend, setAllowResend] = useState(true);
    const [message, setMessage] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [emailReadOnly, setEmailReadOnly] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        setEmailAddress(session.email || "");
    }, []);

    useEffect(() => {


    }, [allowResend]);

    const handleChange = (e) => {
        setMessage(false);
        let value = e.target.value;
        switch (e.target.id) {
            case "formCode":
                value = value.replace(/[^0-9\-]/gi, '').substr(0, 4);
                setCode(value);
                setValid(4 === value.length);
                break;
            case "formEmail":
                setEmailAddress(value);
                if (isEmail({ email: value })) {
                    setAllowResend(true);
                } else {
                    setAllowResend(false);
                }
                break;
            default:
                break;
        }
    }

    const onEdit = ({ id, value }) => {
        //onsole.info("Editing", id, value);

        if ("phone" === id) {
            if (value && value.length > 3) {
                value = asPhoneNumber({ contactInfo: value });
                setSession({
                    phone: value,
                    asOf: Date.now()
                });
            }
        }

    }
    const resendCode = () => {

        setAllowResend(false);
        setSpinner(true);

        let payload = {
            quote: session.quote,
            email: emailAddress
        };

        sendEmailVerification({
            quote: session.quote,
            email: session.email,
            token: session.token,
            next: (data) => {
                setSpinner(false);
                setMessage("Please check your inbox. The new code should arrive within a minute or two.");
                //.info(data);
                if (data.code && data.email) {
                    let newSessionEntry = {
                        verification_code: data.code,
                        email: data.email
                    }
                    setSession(newSessionEntry);
                }
            }
        });

        setTimeout(() => {
            setAllowResend(true);
            setMessage(false);
        }, 8000);
    }

    const verifyCode = () => {
        //onsole.info("Valid?", valid, code, session.email, session.verification_code);
        if (valid && code === "1223") {  //todo replace with session.verification_code
            setEmailOk(true);
        } else {
            setMessage("This code is not valid. Please check your email and/or request a new code.");
        }
    }

    const skipCode = () => {

        setSession({ email_code: 'skipped' });
        setEmailOk(true);
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h5>Email verification</h5>
                </Col>
            </Row>
            {optout ?
                <>
                    {editing ?
                        <>
                            <Row>
                                <Col>
                                    <TextControl label="Phone number" title="Please provide us with the best number to contact you" field="phone" placeholder="Phone number" onChange={onEdit} required={true} type="phone" />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <TextControl label="Email address" title="Please provide us with the best email address to contact you" field="email" placeholder="Email address" onChange={onEdit} required={true} type="email" />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Button onClick={() => setEditing(false)} variant="outline-primary">Confirm</Button>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <p>
                                        An email verification code is a great way to confirm that your contact information is correct, but it is not mandatory.
                                    </p>
                                    <p>
                                        Can you confirm that the following contact information is correct?
                                    </p>
                                    <p>Phone: {session.phone}</p>
                                    <p>Email: {session.email}</p>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button onClick={() => setEmailOk(true)} variant="outline-primary">Confirm</Button>{' '}
                                    <Button onClick={() => setEditing(true)} variant="outline-secondary">Edit</Button>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <small onClick={() => setOptout(false)} className="clicker" style={{ textDecoration: "underline" }}>Back to email verification</small>
                                </Col>
                            </Row>
                        </>
                    }

                </>
                :
                <>
                    <Row>
                        <Col>
                            <p>
                                We sent a 4-digit code to your email address. Please enter this code below.
                            </p>
                            <Form>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" readOnly={emailReadOnly} disabled={emailReadOnly} value={emailAddress} onChange={handleChange} />
                                    {emailReadOnly &&
                                        <Form.Text className="text-muted">
                                            This is the email address you provided. <u className='clicker' onClick={() => setEmailReadOnly(false)}>Change</u>
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formCode">
                                    <Form.Label>4-digit code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter 4-digit code" value={code} onChange={handleChange} />
                                </Form.Group>
                                <Button variant="outline-primary" readOnly={!valid} disabled={!valid} type="button" onClick={verifyCode}>
                                    Confirm
                                </Button>{' '}
                                {allowResend &&
                                    <Button variant="outline-primary" type="button" onClick={resendCode}>
                                        Resend code
                                    </Button>
                                }{' '}

                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <small onClick={() => setOptout(true)} className="clicker" style={{ textDecoration: "underline" }}>Skip email verification</small>
                        </Col>
                    </Row>
                    {spinner &&
                        <Row className='mt-4'>
                            <Col>
                                <div className='dot-pulse'></div>
                            </Col>
                        </Row>
                    }
                    {message &&
                        <Row className='mt-4'>
                            <Col>
                                <Alert variant="info">
                                    {message}
                                </Alert>
                            </Col>
                        </Row>
                    }
                </>
            }


        </Container>

    )
}

