import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { TextControl } from '../controls/text';
import { ToggleWizard } from '../controls/wizards';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import * as productQuestions from '../products/quickterm-questions';

export const Last2 = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [parentQuestion, setParentQuestion] = useState(false);
    const [occupations, setOccupations] = useState(false);
    const [outcome, setOutcome] = useState("incomplete");

    const [questions, setQuestions] = useState([]);
    const [heading, setHeading] = useState("");
    const [helpVisible, setHelpVisible] = useState(false);


    const [clicked, setClicked] = useState(0);


    const [viewMode, setViewMode] = useState("accordion");

    const [ready, setReady] = useState(false);

    const [completed, setCompleted] = useState(false);


    const [updated, setUpdated] = useState(false);

    const navigate = useNavigate();


    const validateScreen = ({ forceValidation }) => {



        if (forceValidation) {
            setLayout({
                lastValidated: "welcome"
            });
            setSession({ validationAttempted: Date.now() });
        }


    }

    useEffect(() => {

        scrollUp();
        setLayout({
            screenReady: false,
            currentScreen: 'last2'
        });

    }, []);

    useEffect(() => {

        //onsole.info("outcome", outcome);

    }, [outcome]);

    const onChange = ({ id, value }) => {

        setUpdated(id);


    }

    const onCompleted = (e) => {

        //onsole.info("Saving", e);
        setSession(e);
        moveForward();

    }

    const moveForward = () => {

        let nextScreen = layout.happyPath[layout.currentScreen];
        let newLayout = {
            currentScreen: nextScreen
        };
        setLayout(newLayout);
        navigate('/' + nextScreen);
        //onsole.info("Moving forwards");
        setCompleted(true);

    }


    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>About you</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <ToggleWizard onCompleted={onCompleted} questions={productQuestions.last2} title={productQuestions.headings.last2} />
                    </Col>
                </Row>
            </Container>
        </>
    )

}
