import { useContext } from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import * as config from '../config.js';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
} from '../libs/contexts.js';
import {
  BaseDesktopFaq,
  BaseDesktopFooter,
  BaseDesktopHeader,
  BaseDesktopMenu,
  BaseDesktopProgressBar,
  BaseDesktopSidebar,
} from './base-desktop.jsx';

export const HeroDesktopLayout = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);

    return (

        <>
            <div className="content-container">
                <HeroDesktopMenu />
                <Container fluid>
                    <Row>
                        <Col className="desktop-area">
                            <Container fluid>
                                <Row>
                                    <Col className={"yes" === layout.iframeVisible ? "offset-xl-1" : "offset-lg-2 offset-xl-3"}>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    <HeroDesktopHeader />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {"yes" === layout.faq ?
                                                        <HeroDesktopFaq />
                                                        :
                                                        <>
                                                            {"jump" === layout.specialView ?
                                                                <p>We are getting your quote ready, please hold...</p>
                                                                :
                                                                <>
                                                                    {"steady" === branding.progressMode &&
                                                                        <HeroDesktopProgressBar />
                                                                    }
                                                                    <MyOutlet></MyOutlet>
                                                                </>
                                                            }</>

                                                    }

                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    {"yes" !== layout.iframeVisible &&
                                        <Col className="col-lg-4 col-md-5 col-xl-3">
                                            {"jump" !== layout.specialView && !["thankyou", "opportunities", "consent", "end"].includes(layout.currentScreen) &&
                                                <HeroDesktopSidebar />
                                            }
                                        </Col>
                                    }
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            {"end" !== layout.currentScreen &&
                <HeroDesktopFooter />
            }


        </>
    )


}

export const HeroDesktopFaq = () => {

    return (
        <>
            <BaseDesktopFaq />
        </>
    );
}

export const HeroDesktopFooter = () => {

    return (
        <>
            <BaseDesktopFooter />
        </>
    );
}

export const HeroDesktopHeader = () => {

    return (
        <>
            <BaseDesktopHeader />
        </>
    )

}

export const HeroDesktopMenu = () => {
    return (
        <BaseDesktopMenu />
    )
}

export const HeroDesktopProgressBar = () => {

    return (
        <BaseDesktopProgressBar />
    )

}

export const HeroDesktopSidebar = () => {

    return (
        <BaseDesktopSidebar />
    )

}
