import {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
  AccordionBasic,
  RadioAccordion,
  SimpleCarousel,
} from '../controls/wizards';
import {
  asPhoneNumber,
  isEmail,
  isPhoneNumber,
} from '../libs/contact';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
  stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import { extractQuestions } from '../libs/workflow';
import * as productQuestions from '../products/quickterm-questions';

export const Misc = () => {

  const { layout, setLayout } = useContext(LayoutContext);

  const navigate = useNavigate();

  useEffect(() => {

    scrollUp();
    setLayout({
      screenReady: true,
      currentScreen: 'misc'
    });


  }, []);

  const validateScreen = () => {

    let requirements = { ...layout.requirements };
    requirements.misc = "pass";

    let newLayout = {
      requirements: requirements
    };
    setLayout(newLayout);
  }


  const moveForward = (e) => {

    let nextScreen = layout.happyPath[layout.currentScreen];
    let newLayout = {
      currentScreen: nextScreen
    };
    setLayout(newLayout);
    navigate('/' + nextScreen);
    //onsole.info("Moving forwards");
    //setCompleted(true);

  }



  return (
    <>
      <SimpleCarousel onCompleted={moveForward} questions={productQuestions.misc} title="Please answer:" />
    </>
  )

}

