// import * as config from '../config.js';
// import { parseJwt } from '../libs/jwt.js';

// const { CognitoIdentityClient, GetIdCommand } = require("@aws-sdk/client-cognito-identity");
// const { fromCognitoIdentityPool } = require("@aws-sdk/credential-provider-cognito-identity");

export const getCredentials = async () => {
    // const cognitoidentity = new CognitoIdentityClient({
    //     credentials: fromCognitoIdentityPool({
    //         client: new CognitoIdentityClient({ region: 'ca-central-1' }),
    //         identityPoolId: config.identityPool
    //     }),
    // });
    // const credentials = await cognitoidentity.config.credentials()



    // // {
    // //    identityId: 'us-west-2:d393294b-ff23-43t6-d8s5-59876321457d',
    // //    accessKeyId: 'ALALA2RZ7KTS7STD3VXLM',
    // //    secretAccessKey: '/AldkSdt67saAddb6vddRIrs32adQCAo99XM6',
    // //    sessionToken: 'IQoJb3JpZ2luX2VjEJj//////////...', // sessionToken cut for brevity
    // //    expiration: 2022-07-17T08:58:10.000Z
    // //  }
    return ''
}