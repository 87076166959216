// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

import {
  forwardRef,
  useEffect,
  useState,
} from 'react';

import {
  FloatingLabel,
  Form,
} from 'react-bootstrap';
import {
  Hint,
  Typeahead,
} from 'react-bootstrap-typeahead';

import { useId } from '@floating-ui/react';

import * as references from '../reference/approve.jsx';

const ResultItem = forwardRef(({ children, active, ...rest }, ref) => {
    const id = useId();
    return (
        <div
            ref={ ref }
            role="option"
            id={ id }
            aria-selected={ active }
            { ...rest }
            style={ {
                background: active ? "lightblue" : "none",
                padding: 4,
                cursor: "default",
                ...rest.style
            } }
        >
            { children }
        </div>
    );
});

const SearchBox = ({ searchIndex, field, hasErrors, placeholder, hasChanged, required, title, initialValue }) => {


    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let baseValues = references.relationships;
        let newValues = [];
        for (let oldValue of baseValues) {
            let value = { ...oldValue };
            value.label = oldValue.name;
            newValues.push(value);
        }
        setOptions(newValues);

    }, []);

    useEffect(() => {

        if (selected) {
            let value;
            if (Array.isArray(selected)) {
                if (selected.length > 0) {
                    value = selected[0];
                }
            } else {
                value = selected;
            }
            if (value) {
                hasChanged({ id: field, value: value });
            }
        }

    }, [selected]);

    return (
        <>
            { placeholder &&
                <Form.Label>{ placeholder }
                </Form.Label>
            }

            <Typeahead
                id="floating-label-example"
                onChange={ setSelected }
                options={ options }
                placeholder={ placeholder }
                renderInput={ ({ inputRef, referenceElementRef, ...inputProps }) => {
                    return (
                        <Hint>
                            <FloatingLabel controlId="floatingLabel" label={ placeholder }>
                                <Form.Control
                                    { ...inputProps }
                                    ref={ (node) => {
                                        inputRef(node);
                                        referenceElementRef(node);
                                    } }
                                />
                            </FloatingLabel>
                        </Hint>
                    );
                } }
                selected={ selected }
            />

            {/* 
            <Form.Control title={ title } className={ hasErrors ? "is-invalid" : "" } required={ required } type="text" placeholder={ placeholder } value={ controlValue || '' } onPaste={ hasChanged } onKeyUp={ onKeyUp } onKeyDown={ onKeyDown } onChange={ (e) => onValueChange(e) } /> */}

        </>
    );

}


export const RelationshipFinder = ({ title, field, value, placeholder, hasErrors, onChange, required }) => {


    return (
        <SearchBox searchIndex={ references.relationships } field={ field } hasErrors={ hasErrors } placeholder={ placeholder } hasChanged={ onChange } title={ title } required={ required } initialValue={ value } />
    )

}