import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { TextControl } from '../controls/text';
import { RadioAccordion } from '../controls/wizards';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
  stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import * as productQuestions from '../products/quickterm-questions';

export const Occupation = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [parentQuestion, setParentQuestion] = useState(false);
    const [occupations, setOccupations] = useState(false);
    const [outcome, setOutcome] = useState("incomplete");


    const [clicked, setClicked] = useState(0);
    const [heading, setHeading] = useState("");


    const [viewMode, setViewMode] = useState("list");




    const [updated, setUpdated] = useState(false);



    useEffect(() => {

        scrollUp();
        setLayout({
            currentScreen: 'occupation',
            screenReady: false
        });

    }, []);



    useEffect(() => {

        if ('completed' === outcome) {
            setLayout({ screenReady: true });
        }


    }, [outcome]);



    const validateScreen = ({ forceValidation }) => {

    }


    const onChange = () => {


    }

    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h5>{productQuestions.headings.occupation}</h5>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        {productQuestions.occupation &&
                            <RadioAccordion questions={productQuestions.occupation} setOutcome={setOutcome} />
                        }
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
