import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Badge,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
} from 'react-bootstrap';
import { BsArrowLeftRight } from 'react-icons/bs';

import {
    LayoutContext,
    SessionContext,
} from '../libs/contexts';
import {
    toCm,
    toFeet,
    toKg,
    toLbs,
} from '../libs/units';

export const HeightControl = ({ mustRevalidate }) => {

    const { session, setSession } = useContext(SessionContext);

    const [hasProblems, setHasProblems] = useState(false); // invalid or missing values
    const [hasBlocker, setHasBlocker] = useState(false); // the invalid or missing values must be made more visible


    /* with this effect we trigger visual warnings (if values are bad) */
    useEffect(() => {
        setHasBlocker(mustRevalidate && hasProblems);
    }, [hasProblems, mustRevalidate]);

    /* with this effect we validate values but we don't trigger a visual warning */
    useEffect(() => {

        switch (session.physical_units_height) {
            case 'metric':
                let cm = parseInt(session.physical_cm);
                if (!isNaN(cm) && cm >= 100 && cm <= 300) {
                    setHasProblems(false);
                } else {
                    setHasProblems(true);
                }
                break;
            default:
                setSession({ physical_units_height: 'imperial' }); // it's the default in the UI
                let feet = parseInt(session.physical_feet);
                if (!isNaN(feet) && feet >= 4 && feet <= 8) {
                    let inches = 0;
                    if (undefined !== session.physical_inches) {
                        let potentialInches = parseInt(session.physical_inches);
                        if (!isNaN(potentialInches) && potentialInches >= 1 && potentialInches <= 11) {
                            setHasProblems(false);
                        } else {
                            setHasProblems(true);
                        }
                    } else {
                        setHasProblems(false);
                    }
                } else {
                    setHasProblems(true);
                }
                break;
        }


    }, [session.physical_units_height, session.physical_feet, session.physical_inches, session.physical_cm]);


    return (
        <Container fluid>
            <Row>
                <Col className="py-0 px-0">
                    <Form.Label>How tall are you?</Form.Label>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col className="col-md-12 py-0 px-0">
                    {"metric" === session.physical_units_height ?
                        <MetricHeight hasErrors={hasBlocker} />
                        :
                        <ImperialHeight hasErrors={hasBlocker} />
                    }
                </Col>
            </Row>
        </Container>
    )

}

export const WeightControl = ({ mustRevalidate }) => {

    const { session, setSession } = useContext(SessionContext);
    const [hasProblems, setHasProblems] = useState(false); // invalid or missing values
    const [hasBlocker, setHasBlocker] = useState(false); // the invalid or missing values must be made more visible

    /* with this effect we trigger visual warnings (if values are bad) */
    useEffect(() => {
        setHasBlocker(mustRevalidate && hasProblems);
    }, [hasProblems, mustRevalidate]);

    /* with this effect we validate values but we don't trigger a visual warning */
    useEffect(() => {

        switch (session.physical_units_weight) {
            case 'metric':
                let kg = parseInt(session.physical_kg);
                if (!isNaN(kg) && kg >= 1 && kg <= 999) {
                    setHasProblems(false);
                } else {
                    setHasProblems(true);
                }
                break;
            default:
                setSession({ physical_units_weight: 'imperial' }); // it's the default in the UI
                let lbs = parseInt(session.physical_lbs);
                if (!isNaN(lbs) && lbs >= 1 && lbs <= 999) {
                    setHasProblems(false);
                } else {
                    setHasProblems(true);
                }
                break;
        }

    }, [session.physical_units_weight, session.physical_lbs, session.physical_kg]);


    return (
        <Container fluid>
            <Row>
                <Col className="py-0 px-0">
                    <Form.Label>What is your weight?</Form.Label>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col className="col-md-12 py-0 px-0">
                    {"metric" === session.physical_units_weight ?
                        <MetricWeight hasErrors={hasBlocker} />
                        :
                        <ImperialWeight hasErrors={hasBlocker} />
                    }
                </Col>
            </Row>
        </Container>
    )

}

export const ImperialHeight = ({ hasErrors }) => {
    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);

    /* with this effect we update a generic label for the height, and we synchronize the metric equivalent */
    useEffect(() => {

        let validSize = false;
        if (undefined !== session.physical_feet && undefined !== session.physical_inches) {
            if ("" !== session.physical_feet && "" !== session.physical_inches) {
                if (parseInt(session.physical_feet) > 0 && parseInt(session.physical_feet) < 9) {
                    if (parseInt(session.physical_inches) >= 0 && parseInt(session.physical_inches) < 12) {
                        let cm = toCm({ feet: session.physical_feet, inches: session.physical_inches });
                        setSession({
                            physical_height: session.physical_feet.toString() + "'" + session.physical_inches.toString(),
                            physical_cm: cm
                        });
                        validSize = true;
                    }
                }
            }
        }
        if (!validSize) {
            setSession({
                physical_height: ""
            });
        }

    }, [session.physical_feet, session.physical_inches]);

    let feetItems = [4, 5, 6, 7].map((item) =>
        <option value={item} key={item}>{item} ft</option>
    );

    let inchItems = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) =>
        <option value={item} key={item}>{item} in</option>
    );

    const onValueChange = (e) => {

        let newSession = {};
        newSession["physical_" + e.target.id] = e.target.value;
        setSession(newSession);

    }

    const toggleUnits = (syncOnly) => {

        let newSession = {};

        if (undefined === syncOnly) {
            newSession.physical_units_height = "metric";
        }
        setSession(newSession);
    }

    return (

        <Container fluid>
            <Row>
                <Col className='col-5'>
                    <Form.Select className={hasErrors ? "is-invalid" : ""} aria-label="feet" id="feet" value={session.physical_feet || -1} onChange={(e) => onValueChange(e)}>
                        <option disabled value="-1" key="-1">Choose:</option>
                        {feetItems}
                    </Form.Select>
                </Col>
                <Col className='col-5'>
                    <Form.Select className={hasErrors ? "is-invalid" : ""} aria-label="inches" id="inches" value={session.physical_inches || -1} onChange={(e) => onValueChange(e)}>
                        <option disabled value="-1" key="-1">Choose:</option>
                        {inchItems}
                    </Form.Select>
                </Col>
                <Col className='col-2'>
                    <Badge id="unit-toggle-cm" className="clicker" onClick={() => toggleUnits()}>
                        {layout.isDesktop &&
                            <BsArrowLeftRight />
                        } cm</Badge>
                </Col>
            </Row>
        </Container>

    )

}


export const MetricHeight = ({ hasErrors }) => {

    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);

    const onValueChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        if (isNaN(parseInt(newValue))) {
            //do nothing instead?
            newValue = "";
        } else {
            if (parseInt(newValue) > 225) {
                newValue = "0";
            } else {
                newValue = parseInt(newValue).toString().substring(0, 3);
            }
        }

        let newSession = {
            physical_cm: newValue
        };
        if (newValue) {
            newSession.physical_height = newValue + " cm";
        }

        // we want to save the alternate unit right away
        let imperialValue = fromMetric(newValue);
        newSession.physical_feet = imperialValue.feet;
        newSession.physical_inches = imperialValue.inches;
        setSession(newSession);

    }

    const fromMetric = function (cm) {
        return toFeet({ cm: cm });
    }

    const toggleUnits = (syncOnly) => {
        let newSession = {
        };
        if (undefined === syncOnly) {
            newSession.physical_units_height = "imperial";
        }
        setSession(newSession);
    }

    return (
        <Container fluid>
            <Row>
                <Col className='col-10'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">cm</InputGroup.Text>
                        <Form.Control className={hasErrors ? "is-invalid" : ""} type="text" placeholder="Enter height in cm" value={session.physical_cm || ""} onChange={e => onValueChange(e)} />
                    </InputGroup>


                </Col>
                <Col>
                    <Badge id="unit-toggle-ft" className="clicker" onClick={() => toggleUnits()}>
                        {layout.isDesktop &&
                            <BsArrowLeftRight />
                        }
                        ft</Badge>
                </Col>
            </Row>
        </Container>
    )

}



export const ImperialWeight = ({ hasErrors }) => {

    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);

    const onValueChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        if (isNaN(parseInt(newValue))) {
            //do nothing instead?
            newValue = "";
        } else {
            if (parseInt(newValue) > 999) {
                newValue = "1";
            } else {
                newValue = parseInt(newValue).toString().substring(0, 3);
            }
        }

        let newSession = {
            physical_lbs: newValue
        };
        if (newValue) {
            newSession.physical_weight = newValue + " lbs";
        }

        // we want to save the alternate unit right away
        newSession.physical_kg = toKg({ lbs: newValue });

        setSession(newSession);



    }


    const toggleUnits = (syncOnly) => {

        let newSession = {};

        //newSession.physical_weight = newSession.physical_kg + " kg";

        if (undefined === syncOnly) {
            newSession.physical_units_weight = "metric";
        }
        setSession(newSession);
    }

    return (
        <Container fluid>
            <Row>
                <Col className='col-10'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">lbs</InputGroup.Text>
                        <Form.Control className={hasErrors ? "is-invalid" : ""} type="text" placeholder="Enter weight in lbs" value={session.physical_lbs || ""} onChange={e => onValueChange(e)} />
                    </InputGroup>
                </Col>
                <Col>
                    <Badge id="unit-toggle-kg" className="clicker" onClick={() => toggleUnits()}>
                        {layout.isDesktop &&
                            <BsArrowLeftRight />
                        }
                        kg</Badge>
                </Col>
            </Row>
        </Container>
    )

}


export const MetricWeight = ({ hasErrors }) => {
    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    const onValueChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        if (isNaN(parseInt(newValue))) {
            //do nothing instead?
            newValue = "";
        } else {
            if (parseInt(newValue) > 999) {
                newValue = "1";
            } else {
                newValue = parseInt(newValue).toString().substring(0, 3);
            }
        }

        let newSession = {
            physical_kg: newValue
        };
        if (newValue) {
            newSession.physical_weight = newValue + " kg";
        }

        // we want to save the alternate unit right away
        newSession.physical_lbs = toLbs({ kg: newValue })
        setSession(newSession);

    }

    const toggleUnits = (syncOnly) => {
        let newSession = {};

        if (undefined === syncOnly) {
            newSession.physical_units_weight = "imperial";
        }
        setSession(newSession);
    }

    return (
        <Container fluid>
            <Row>
                <Col className='col-10'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
                        <Form.Control className={hasErrors ? "is-invalid" : ""} type="text" placeholder="Enter weight in kg" value={session.physical_kg || ""} onChange={e => onValueChange(e)} />
                    </InputGroup>
                </Col>
                <Col>
                    <Badge id="unit-toggle-lb" className="clicker" onClick={() => toggleUnits()}>
                        {layout.isDesktop &&
                            <BsArrowLeftRight />
                        }
                        lbs</Badge>
                </Col>
            </Row>
        </Container>

    )

}


