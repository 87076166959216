export const ageMethod = "age nearest";
export const gateway = "https://qat-belowcost-shopper-api.link-nonprod.wawanesalife.com";
export const assets = "https://qat-assets.link-nonprod.wawanesalife.com/brokers/belowcost";
export const googleApiKey = "AIzaSyD8GAXLH7FxUmF13tfHgXVAU6fWk66UH3k";
export const appToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJiZWxvd2Nvc3QiLCJuYW1lIjoiYmVsb3djb3N0IiwiaWF0IjoxNzMzMjY0NzMxLCJleHAiOjE4OTEwNTI3MzF9._KyEPLE3440hXwWEY6srHbfinoCCu8SBVL-oCMB3X_w";
export const brokerConfig = {
	enabled: "yes",
	authorized_nb: "yes",
	authorized_mb: "yes",
	authorized_pe: "yes",
	authorized_yt: "yes",
	authorized_sk: "yes",
	authorized_qc: "no",
	authorized_nt: "yes",
	authorized_nu: "yes",
	authorized_ns: "yes",
	authorized_nl: "yes",
	authorized_on: "yes",
	authorized_ab: "yes",
	authorized_bc: "yes",
	"split": '0.6'
};
export const brokerBranding = {
	"qat-belowcost-shopper.link-nonprod.wawanesalife.com": {
		a: {
			broker: "belowcost",
			accessibilityLink: "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
			themeSwitcher: "show",
			privacyLink: "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
			scope: "belowcost#branding#qat-belowcost-shopper.link-nonprod.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "bob@belowcost.lol",
			video_link: "null",
			map2: [
				"845 Dakota St Unit 30",
				"Winnipeg",
				"MB",
				"R2M 5M3",
			],
			environment_id: "qat",
			map1: [
				"840 Waverley St",
				"Winnipeg",
				"MB",
				"R3T 5Z7",
			],
			brands: [
				"qat-belowcost-shopper.link-nonprod.wawanesalife.com",
			],
			map4: [
				"1440 Jack Blick Ave #101c",
				"Winnipeg",
				"MB",
				"R3G 0L4",
			],
			map3: [
				"3020 Preston Ave S #100",
				"Saskatoon",
				"SK",
				"S7T 0V2",
			],
			"maps": {
				"True North Tower": [
					"236 Carlton",
					"Winnipeg",
					"MB",
					"R3C 1P5",
				],
				"River Park branch": [
					"845 Dakota St Unit 30",
					"Winnipeg",
					"MB",
					"R2M 5M3",
				],
				"Jack Blick branch": [
					"1440 Jack Blick Ave",
					"Winnipeg",
					"MB",
					"R3G 0L4",
				],
				"Saskatoon branch": [
					"100-3020 Preston Ave S",
					"Saskatoon",
					"SK",
					"S7T 0V2",
				],
				"Waverley branch": [
					"840 Waverley St",
					"Winnipeg",
					"MB",
					"R3T 5Z7",
				],
			},
			introduction: "null",
			phone: "(204) 555-4321",
			menuPhone: "button",
			environment: "qat",
			website: "https://qat.belowcost.net/",
			ftcLink: "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "show",
			photos: [
				"carousel1.jpg",
				"carousel2.jpg",
				"carousel3.jpg",
				"carousel4.jpg",
				"carousel5.jpg",
				"carousel6.jpg",
				"carousel7.jpg",
				"carousel8.jpg",
				"carousel9.jpg",
				"carousel10.jpg",
				"carousel11.jpg",
			],
			photo4: "show",
			photo1: "show",
			photo2: "show",
			approve_user: "link_belowcost",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "qat-belowcost-shopper.link-nonprod.wawanesalife.com",
		},
		b: {
			broker: "belowcost",
			accessibilityLink: "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
			themeSwitcher: "show",
			privacyLink: "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
			scope: "belowcost#branding#qat-belowcost-shopper.link-nonprod.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "bob@belowcost.lol",
			video_link: "null",
			map2: [
				"845 Dakota St Unit 30",
				"Winnipeg",
				"MB",
				"R2M 5M3",
			],
			environment_id: "qat",
			map1: [
				"840 Waverley St",
				"Winnipeg",
				"MB",
				"R3T 5Z7",
			],
			brands: [
				"qat-belowcost-shopper.link-nonprod.wawanesalife.com",
			],
			map4: [
				"1440 Jack Blick Ave #101c",
				"Winnipeg",
				"MB",
				"R3G 0L4",
			],
			map3: [
				"3020 Preston Ave S #100",
				"Saskatoon",
				"SK",
				"S7T 0V2",
			],
			"maps": {
				"True North Tower": [
					"236 Carlton",
					"Winnipeg",
					"MB",
					"R3C 1P5",
				],
				"River Park branch": [
					"845 Dakota St Unit 30",
					"Winnipeg",
					"MB",
					"R2M 5M3",
				],
				"Jack Blick branch": [
					"1440 Jack Blick Ave",
					"Winnipeg",
					"MB",
					"R3G 0L4",
				],
				"Saskatoon branch": [
					"100-3020 Preston Ave S",
					"Saskatoon",
					"SK",
					"S7T 0V2",
				],
				"Waverley branch": [
					"840 Waverley St",
					"Winnipeg",
					"MB",
					"R3T 5Z7",
				],
			},
			introduction: "null",
			phone: "(204) 555-4321",
			menuPhone: "button",
			environment: "qat",
			website: "https://qat.belowcost.net/",
			ftcLink: "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "show",
			photos: [
				"carousel1.jpg",
				"carousel2.jpg",
				"carousel3.jpg",
				"carousel4.jpg",
				"carousel5.jpg",
				"carousel6.jpg",
				"carousel7.jpg",
				"carousel8.jpg",
				"carousel9.jpg",
				"carousel10.jpg",
				"carousel11.jpg",
			],
			photo4: "show",
			photo1: "show",
			photo2: "show",
			approve_user: "link_belowcost",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "qat-belowcost-shopper.link-nonprod.wawanesalife.com",
		},
	},
};

//updated on Tue Dec 03 2024 16:25:31 GMT-0600 (Central Standard Time)
