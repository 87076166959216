import React, {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Accordion,
    Button,
    Card,
    Carousel,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';

export const DetailsForm = ({ answer, onCancel, onSave, question, title }) => {

    const [value, setValue] = useState("");

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    return (

        <Form>
            <Form.Group className="mb-3" controlId="details">
                <Form.Label>Additional details</Form.Label>
                <Form.Control type="text" placeholder="Provide details" value={value} onChange={handleChange} />
                <Form.Text className="text-muted">
                    This is optional.
                </Form.Text>
            </Form.Group>
            <Button variant="primary" type="button" onClick={(e) => onSave(value)}>
                Confirm
            </Button>{' '}
            <Button variant="outline-primary" type="button" onClick={(e) => onCancel()}>
                Cancel
            </Button>
        </Form>

    );

}

export const SimpleCarousel = ({ onCompleted, questions, title }) => {

    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    const [index, setIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [viewMode, setViewMode] = useState("carousel");


    useEffect(() => {

        if (questions) {
            setCurrentQuestion(questions[index]);
        }

    }, [questions]);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    }

    const nextSlide = (e) => {

        let answer = {
            id: currentQuestion.no,
            text: "No",
            path: "application"
        };

        let newSessionEntry = {};
        newSessionEntry["A" + currentQuestion.id] = "No";
        setSession(newSessionEntry);
        moveOn();

    }

    const hideDetails = (disqualify) => {

        if (undefined !== disqualify) {

            // we have confirmed the wrong answer
            let answer = {
                id: currentQuestion.yes,
                text: "Yes",
                path: "queue",
                details: disqualify
            };

            let newSessionEntry = {};
            newSessionEntry["A" + currentQuestion.id] = "Yes";//answer;
            setSession(newSessionEntry);
            moveOn();

        }


        setViewMode("carousel");
    }

    const moveOn = () => {
        let newIndex = index + 1;
        if (newIndex < questions.length) {
            setIndex(newIndex);
            setCurrentQuestion(questions[newIndex]);
        } else {
            onCompleted();
        }
    }

    const showDetails = () => {
        setViewMode("details");
    }


    return (
        <>
            {"carousel" === viewMode ?
                <Container fluid>

                    <Row>
                        <h5>{title}</h5>
                    </Row>

                    <Row className='mt-2'>
                        <Col className={layout.isDesktop ? 'col-sm-12 col-lg-8 col-xl-9' : 'col-12'}>
                            <Card className="questionBox border-0">
                                <Card.Body>
                                    <Carousel interval={null} controls={false} fade={false} indicators={false} keyboard={false} touch={false} wrap={false} variant="dark" activeIndex={index} onSelect={handleSelect}>
                                        {questions.map(question => (
                                            <Carousel.Item key={question.id}>
                                                <p>{question.copy}</p>
                                                {question.bullets &&
                                                    <ul>
                                                        {question.bullets.map(bullet => (
                                                            <li key={bullet}>{bullet}</li>
                                                        ))}
                                                    </ul>
                                                }
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Card.Body>
                            </Card>

                        </Col>
                        {layout.isDesktop &&
                            <Col className='col-sm-12 col-lg-4 col-xl-3 text-center'>
                                <Button onClick={nextSlide} variant="outline-primary">No</Button>{' '}
                                <Button onClick={showDetails} variant="outline-secondary">Yes</Button>
                            </Col>
                        }
                    </Row>
                    {!layout.isDesktop &&
                        <Row>
                            <Col className='col-sm-12 col-md-3 text-center'>
                                <Button onClick={nextSlide} variant="outline-primary">No</Button>{' '}
                                <Button onClick={showDetails} variant="outline-secondary">Yes</Button>
                            </Col>
                        </Row>
                    }
                </Container>
                :
                <DetailsForm onCancel={hideDetails} onSave={hideDetails} question={currentQuestion} title={title} />
            }
        </>

    )

}



export const ToggleWizard = ({ onCompleted, questions, title }) => {

    const { session, setSession } = useContext(SessionContext);
    const [valid, setValid] = useState(true);
    const [selected, setSelected] = useState({});
    const [ready, setReady] = useState(false);
    const [helpColor, setHelpColor] = useState("");

    const getAllQuestions = () => {
        let allQuestions = [];
        for (let question of questions) {
            allQuestions.push(question);
            if (question.drilldown) {
                for (let drilldown of question.drilldown) {
                    allQuestions.push(drilldown);
                }
            }
        }
        return allQuestions;
    }

    useEffect(() => {


        let newSelected = {};
        for (let question of getAllQuestions()) {
            if (undefined !== session["A" + question.id]) {
                if (undefined !== session["A" + question.id].text) {
                    if ("Yes" === session["A" + question.id].text) {
                        newSelected[question.id] = "selected";
                    }
                }
            }
        }

        setSelected(newSelected);
        setReady(true);

    }, []);


    useEffect(() => {

        validateSelection(selected);

    }, [selected]);


    const handleSelect = (e) => {

        let newSelected = { ...selected };
        if (e.target.checked) {
            newSelected[e.target.id] = "selected";
        } else {
            delete newSelected[e.target.id];
            // we need to unselect children
            for (let level1 of questions) {
                if (level1.id === e.target.id) {
                    if (level1.drilldown) {
                        for (let level2 of level1.drilldown) {
                            delete newSelected[level2.id];
                        }
                    }
                }
            }
        }

        setSelected(newSelected);

    }

    const saveAnswers = (e) => {

        let newSessionEntry = {};


        for (let question of getAllQuestions()) {
            if ("selected" === selected[question.id]) {
                // we have possibly a disqualifying answer
                let answer = {
                    id: question["yes"],
                    text: "Yes",
                    path: "queue"
                };
                newSessionEntry["A" + question.id] = answer;

            } else {
                let answer = {
                    id: question["no"],
                    text: "No",
                    path: "application"
                };
                newSessionEntry["A" + question.id] = answer;

            }

        }
        onCompleted(newSessionEntry);

    }

    const emphasizeHelp = () => {

        if ("light" === helpColor) {
            setHelpColor("warning");
        } else {
            setHelpColor("light");
        }

    }

    const validateSelection = (selection) => {

        let problems = 0;
        for (let question of questions) {
            if (question.drilldown) {
                if ("selected" === selection[question.id]) {
                    // we need to check if each drilldown group has an answer
                    let drilldowns = {};
                    for (let drilldown of question.drilldown) {
                        if (undefined === drilldowns[drilldown.drilldownGroup]) {
                            if (undefined !== drilldown.drilldownGroup) {
                                drilldowns[drilldown.drilldownGroup] = "incomplete";
                            }
                        }
                        if ("selected" === selection[drilldown.id]) {
                            drilldowns[drilldown.drilldownGroup] = "OK";
                        }
                    }

                    for (let [k, v] of Object.entries(drilldowns)) {
                        if ("incomplete" === v && undefined !== k) {
                            problems++;
                        }
                    }

                }

            }
        }



        if (problems > 0) {
            setValid(false);
        } else {
            setValid(true);
            setHelpColor("");
        }

    }



    return (
        <Container fluid>

            <Row>
                <h5>{title}</h5>
            </Row>

            {ready &&
                <>
                    <Row className='mt-4'>
                        <Col>

                            {questions.map(question => (
                                <React.Fragment key={question.id}>
                                    <Form.Check
                                        checked={"selected" === selected[question.id]}
                                        type="switch"
                                        id={question.id}
                                        label={question.copy}
                                        onChange={handleSelect}
                                    />
                                    {question.drilldown &&
                                        <>
                                            {"selected" === selected[question.id] &&
                                                <>
                                                    <Card className="mt-3 mb-3" bg={helpColor}>
                                                        {!valid &&
                                                            <Card.Header>
                                                                Please select one answer in each group
                                                            </Card.Header>
                                                        }
                                                        <Card.Body>
                                                            {question.drilldown.map(drilldown => (
                                                                <React.Fragment key={question.id + "#" + drilldown.id + "#container"}>
                                                                    {"heading" === drilldown.type ?
                                                                        <Card.Title key={question.id + "#" + drilldown.id}>{drilldown.copy}</Card.Title>
                                                                        :
                                                                        <Form.Check
                                                                            checked={"selected" === selected[drilldown.id]}
                                                                            key={question.id + "#" + drilldown.id}
                                                                            name={drilldown.drilldownGroup}
                                                                            type="radio"
                                                                            id={drilldown.id}
                                                                            label={drilldown.copy}
                                                                            onChange={handleSelect}
                                                                        />
                                                                    }
                                                                </React.Fragment>
                                                            ))}
                                                        </Card.Body>
                                                    </Card>
                                                </>
                                            }
                                        </>
                                    }
                                    {question.followUp &&
                                        <>
                                            {"selected" === selected[question.id] &&
                                                <>
                                                    <Card className="mt-3 mb-3" bg={helpColor}>
                                                        {!valid &&
                                                            <Card.Header>
                                                                Please select one answer in each group
                                                            </Card.Header>
                                                        }
                                                        <Card.Body>
                                                            {question.followUp.map(followUp => (
                                                                <React.Fragment key={question.id + "#" + followUp.id + "#container"}>
                                                                    <Card.Text>
                                                                        {followUp.copy}
                                                                    </Card.Text>
                                                                    {Object.entries(followUp.choices).map(([choiceId, choice]) => (
                                                                        <React.Fragment key={followUp.id + "#" + choiceId}>
                                                                            <Form.Check
                                                                                checked={"selected" === selected[choiceId]}
                                                                                name={followUp.id + "#options"}
                                                                                type="radio"
                                                                                id={choiceId}
                                                                                label={choice.copy}
                                                                                onChange={handleSelect}
                                                                            />
                                                                        </React.Fragment>
                                                                    ))}


                                                                </React.Fragment>
                                                            ))}
                                                        </Card.Body>
                                                    </Card>
                                                </>
                                            }
                                        </>
                                    }
                                </React.Fragment>
                            ))}

                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            {valid ?
                                <>
                                    {0 === Object.entries(selected).length ?
                                        <Button onClick={saveAnswers} variant="primary">None of the above</Button>
                                        :
                                        <Button onClick={saveAnswers} variant="primary">Confirm</Button>
                                    }
                                </>
                                :
                                <>
                                    {0 === Object.entries(selected).length ?
                                        <Button onClick={emphasizeHelp} variant="outline-primary">None of the above</Button>
                                        :
                                        <Button onClick={emphasizeHelp} variant="outline-primary">Confirm</Button>
                                    }
                                </>
                            }


                        </Col>
                    </Row>
                </>
            }
        </Container>



    )

}


export const SimpleToggleWizard = ({ onCompleted, questions, title }) => {

    const { session, setSession } = useContext(SessionContext);
    const [valid, setValid] = useState(true);
    const [selected, setSelected] = useState({});
    const [ready, setReady] = useState(false);
    const [helpColor, setHelpColor] = useState("");

    const expandQuestions = () => {
        let allQuestions = [];
        for (let question of questions) {
            allQuestions.push(question);
            if (question.followUp) {
                for (let followUp of question.followUp) {
                    // we treat each possible answer as a separate question to mimic Approve
                    // in this scenario, every radio button is a Yes/No question in disguise, including buttons labeles "Yes" or "no"
                    // this is confusing but that's how these questions are structured upstream
                    for (let [choiceId, choice] of Object.entries(followUp.choices)) {
                        allQuestions.push(choice);
                    }
                }
            }
        }
        return allQuestions;
    }

    useEffect(() => {


        let newSelected = {};

        for (let question of expandQuestions()) {
            if (undefined !== session["A" + question.id]) {
                if (undefined !== session["A" + question.id].text) {
                    if ("Yes" === session["A" + question.id].text) {
                        newSelected[question.id] = "selected";
                    }
                }
            }

        }

        setSelected(newSelected);
        setReady(true);

    }, []);


    useEffect(() => {

        validateSelection(selected);

    }, [selected]);


    const handleSelect = (e) => {

        let newSelected = { ...selected };
        if (e.target.checked) {
            newSelected[e.target.id] = "selected";
        } else {
            delete newSelected[e.target.id];
            // we need to unselect children
            for (let question of questions) {
                if (question.id === e.target.id) {
                    if (question.followUp) {
                        for (let followUp of question.followUp) {
                            delete newSelected[followUp.id];
                            for (let [choiceId, choice] of Object.entries(followUp.choices)) {
                                delete newSelected[choiceId];
                            }
                        }
                    }
                }
            }
        }

        setSelected(newSelected);

    }

    const saveAnswers = (e) => {

        let newSessionEntry = {};


        for (let question of expandQuestions()) {
            if ("selected" === selected[question.id]) {
                // we have possibly a disqualifying answer
                let answer = {
                    id: question["yes"],
                    text: "Yes",
                    path: "queue"
                };
                newSessionEntry["A" + question.id] = "Yes"; //answer;

            } else {
                let answer = {
                    id: question["no"],
                    text: "No",
                    path: "application"
                };
                newSessionEntry["A" + question.id] = "No";//answer;
            }

        }
        onCompleted(newSessionEntry);

    }

    const emphasizeHelp = () => {

        if ("light" === helpColor) {
            setHelpColor("warning");
        } else {
            setHelpColor("light");
        }

    }

    const validateSelection = (selection) => {

        let problems = 0;

        for (let question of questions) {
            if (question.followUp) {
                if ("selected" === selection[question.id]) {
                    let expected = question.followUp.length;
                    for (let followUp of question.followUp) {
                        for (let [choiceId, choice] of Object.entries(followUp.choices)) {
                            if ("selected" === selected[choice.id]) {
                                expected = expected - 1;
                            }
                        }
                    }
                    if (expected > 0) {
                        problems++;
                    }
                }

            }
        }
        if (problems > 0) {
            setValid(false);
        } else {
            setValid(true);
            setHelpColor("");
        }

    }

    return (
        <Container fluid>

            <Row>
                <h5>{title}</h5>
            </Row>

            {ready &&
                <>
                    <Row className='mt-4'>
                        <Col>

                            {questions.map(question => (
                                <React.Fragment key={question.id}>
                                    <Form.Check
                                        checked={"selected" === selected[question.id]}
                                        type="switch"
                                        id={question.id}
                                        label={question.copy}
                                        onChange={handleSelect}
                                    />
                                    {question.followUp &&
                                        <>
                                            {"selected" === selected[question.id] &&
                                                <>
                                                    <Card className="mt-3 mb-3" bg={helpColor}>
                                                        {!valid &&
                                                            <Card.Header>
                                                                Please select one answer in each group
                                                            </Card.Header>
                                                        }
                                                        <Card.Body>
                                                            {question.followUp.map(followUp => (
                                                                <React.Fragment key={question.id + "#" + followUp.id + "#container"}>
                                                                    <Card.Text>
                                                                        {followUp.copy}
                                                                    </Card.Text>
                                                                    <div className="mb-4">
                                                                        {Object.entries(followUp.choices).map(([choiceId, choice]) => (
                                                                            <React.Fragment key={followUp.id + "#" + choiceId}>
                                                                                <Form.Check
                                                                                    checked={"selected" === selected[choiceId]}
                                                                                    name={followUp.id + "#options"}
                                                                                    type="radio"
                                                                                    id={choiceId}
                                                                                    label={choice.copy}
                                                                                    onChange={handleSelect}
                                                                                />
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </div>

                                                                </React.Fragment>
                                                            ))}
                                                        </Card.Body>
                                                    </Card>
                                                </>
                                            }
                                        </>
                                    }
                                </React.Fragment>
                            ))}

                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            {valid ?
                                <>
                                    {0 === Object.entries(selected).length ?
                                        <Button onClick={saveAnswers} variant="primary">None of the above</Button>
                                        :
                                        <Button onClick={saveAnswers} variant="primary">Confirm</Button>
                                    }
                                </>
                                :
                                <>
                                    {0 === Object.entries(selected).length ?
                                        <Button onClick={emphasizeHelp} variant="outline-primary">None of the above</Button>
                                        :
                                        <Button onClick={emphasizeHelp} variant="outline-primary">Confirm</Button>
                                    }
                                </>
                            }


                        </Col>
                    </Row>
                </>
            }
        </Container>



    )

}



export const RadioAccordion = ({ questions, setOutcome }) => {

    const { session, setSession } = useContext(SessionContext);
    const [activeKey, setActiveKey] = useState(false);
    const [answers, setAnswers] = useState({});
    const [ready, setReady] = useState(false);
    const [questionId, setQuestionId] = useState(0);

    useEffect(() => {

        if (questions.length > 0) {
            let newAnswers = {};
            let newQuestionId = 0;
            for (let i = 0; i < questions.length; i++) {
                if (undefined !== session["occupations_" + i]) {
                    newAnswers["occupations_" + i] = session["occupations_" + i];
                } else {
                    newAnswers["occupations_" + i] = {};
                }
                newQuestionId = questions[i].parentId;
            }
            setAnswers(newAnswers);
            setQuestionId(newQuestionId);
            changeFocus();
            setReady(true);
        }

    }, [questions]);

    useEffect(() => {

        if (Object.entries(answers).length > 0) {
            changeFocus();
            if (Object.entries(answers).length === questions.length) {
                // everything has been answered
                let disqualified = [];
                let qualified = [];

                for (let [Aid, answer] of Object.entries(answers)) {
                    switch (answer.text) {
                        case "No":
                            qualified.push(answer.id);
                            break;
                        case "Yes":
                            disqualified.push(answer.id);
                            break;
                        default:
                            break;
                    }
                }
                let newSessionData = {};
                if (disqualified.length > 0) {
                    setOutcome("disqualified");
                    newSessionData["A" + questionId] = "Yes";
                } else {
                    if (qualified.length === questions.length) {
                        setOutcome("completed");
                        newSessionData["A" + questionId] = "No";
                    } else {
                        setOutcome("incomplete");
                        newSessionData["A" + questionId] = "";
                    }
                }
                setSession(newSessionData);
            } else {
                setOutcome("incomplete");
            }
        } else {
            setOutcome("incomplete");
        }

    }, [answers, questionId]);

    const changeFocus = () => {

        for (let i = 0; i < questions.length; i++) {
            if (undefined === answers["occupations_" + i] || !["Yes", "No"].includes(answers["occupations_" + i].text)) {
                setActiveKey("occupations_" + i);
                break;
            }
        }

    }

    const onCheck = (bucket, choice, text) => {
        //todo refactor to use bundle path
        let newAnswers = { ...answers };
        let newSessionEntry = {};
        newAnswers["occupations_" + bucket] = {
            id: choice,
            text: text,
            path: "Yes" === text ? "queue" : "application"
        };
        setAnswers(newAnswers);

        if ("Yes" === text) {


            if (undefined === session.disqualified) {
                newSessionEntry.disqualified = {};
            } else {
                newSessionEntry.disqualified = { ...session.disqualified };
            }
            if (undefined === newSessionEntry.disqualified.occupation) {
                newSessionEntry.disqualified.occupation = [];
            }
            newSessionEntry.disqualified.occupation.push("occupation_" + bucket);

        } else {
            if (undefined !== session.disqualified && undefined !== session.disqualified.occupation) {
                newSessionEntry.disqualified = { ...session.disqualified };
                let newOccupations = [];
                for (let item of session.disqualified.occupation) {
                    if (item !== "occupation_" + bucket) {
                        newOccupations.push(item);
                    }
                }
                newSessionEntry.disqualified.occupation = newOccupations;
            }
        }

        newSessionEntry["occupations_" + bucket] = newAnswers["occupations_" + bucket];
        setSession(newSessionEntry);
    }

    return (
        <>
            {ready ?
                <Accordion activeKey={activeKey} flush>
                    {questions.map((question, index) =>

                        <Accordion.Item key={index + 1} eventKey={"occupations_" + index}>

                            <Accordion.Header className="bg-info" onClick={() => setActiveKey("occupations_" + index)}>

                                <Container fluid>
                                    <Row>
                                        <Col className="text-left col-md-4">
                                            {index + 1} of {questions.length}
                                        </Col>
                                        <Col className='col-md-8 text-end text-right'>
                                            {answers["occupations_" + index] && ["Yes", "No"].includes(answers["occupations_" + index].text) &&
                                                <>
                                                    <small className='text-muted'>your answer: {answers["occupations_" + index].text}</small>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </Container>

                            </Accordion.Header>

                            <Accordion.Body>
                                <div>
                                    <ul>
                                        {question.map((choice, choiceIndex) =>
                                            <li key={choiceIndex}>{choice.copy}</li>
                                        )}
                                    </ul>
                                </div>
                                <div className="mb-3 mt-4">
                                    <Form.Check
                                        inline
                                        label="No"
                                        name={"occupations_" + index}
                                        type="radio"
                                        id={question.no}
                                        onChange={(e) => onCheck(index, index, "No")}
                                        checked={answers["occupations_" + index] && "No" === answers["occupations_" + index].text}
                                    />

                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name={"occupations_" + index}
                                        type="radio"
                                        id={question.yes}
                                        onChange={(e) => onCheck(index, index, "Yes")}
                                        checked={answers["occupations_" + index] && "Yes" === answers["occupations_" + index].text}
                                    />

                                </div>

                            </Accordion.Body>
                        </Accordion.Item>

                    )}
                </Accordion>
                :
                <></>
            }
        </>
    );

}


export const ToggleAccordion = ({ questions, setOutcome }) => {

    const { session, setSession } = useContext(SessionContext);
    const [activeKey, setActiveKey] = useState(false);
    const [answers, setAnswers] = useState({});
    const [ready, setReady] = useState(false);


    useEffect(() => {

        if (questions.length > 0) {
            let newAnswers = {};

            for (let question of questions) {
                if (undefined !== session["A" + question.id]) {
                    newAnswers["A" + question.id] = session["A" + question.id];
                } else {
                    newAnswers["A" + question.id] = {};
                }
            }

            setAnswers(newAnswers);
            changeFocus();
            setReady(true);
        }

    }, []);

    useEffect(() => {

        if (Object.entries(answers).length > 0) {
            changeFocus();
            if (Object.entries(answers).length === questions.length) {
                // everything has been answered
                let disqualified = [];
                let qualified = [];

                for (let [Aid, answer] of Object.entries(answers)) {
                    switch (answer.text) {
                        case "No":
                            qualified.push(answer.id);
                            break;
                        case "Yes":
                            disqualified.push(answer.id);
                            break;
                        default:
                            break;
                    }
                }
                if (disqualified.length > 0) {
                    setOutcome("disqualified");
                } else {
                    if (qualified.length === questions.length) {
                        setOutcome("completed");
                    } else {
                        setOutcome("incomplete");
                    }

                }
            } else {
                setOutcome("incomplete");
            }
        } else {
            setOutcome("incomplete");
        }

    }, [answers]);

    const changeFocus = () => {

        for (let question of questions) {
            if (undefined === answers["A" + question.id] || !["Yes", "No"].includes(answers["A" + question.id].text)) {
                setActiveKey("A" + question.id);
                break;
            }
        }

    }

    const onCheck = (question, choice, text) => {

        let newAnswers = { ...answers };
        let newSessionEntry = {};
        newAnswers["A" + question] = {
            id: choice,
            text: text,
            path: "Yes" === text ? "queue" : "application"
        };
        setAnswers(newAnswers);
        newSessionEntry["A" + question] = newAnswers["A" + question];
        //onsole.info("Saving answers", newSessionEntry);
        setSession(newSessionEntry);
    }


    return (
        <Container fluid>
            {ready ?
                <Row>
                    <Col>
                        <Accordion activeKey={activeKey} flush>
                            {questions.map((question, index) =>

                                <Accordion.Item key={index + 1} eventKey={"A" + question.id}>

                                    <Accordion.Header className="bg-info" onClick={() => setActiveKey("A" + question.id)}>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    {index + 1} of {questions.length}
                                                </Col>
                                                <Col className='text-end text-right'>
                                                    {answers["A" + question.id] && ["Yes", "No"].includes(answers["A" + question.id].text) &&
                                                        <>
                                                            <small className='text-muted'>your answer: {answers["A" + question.id].text}</small>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>

                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <div>
                                            {question.copy}
                                        </div>
                                        <div className="mb-3 mt-4">
                                            <Form.Check
                                                inline
                                                label="No"
                                                name={"A" + question.id}
                                                type="radio"
                                                id={question.no}
                                                onChange={(e) => onCheck(question.id, question.no, "No")}
                                                checked={answers["A" + question.id] && "No" === answers["A" + question.id].text}
                                            />

                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name={"A" + question.id}
                                                type="radio"
                                                id={question.yes}
                                                onChange={(e) => onCheck(question.id, question.yes, "Yes")}
                                                checked={answers["A" + question.id] && "Yes" === answers["A" + question.id].text}
                                            />

                                        </div>

                                    </Accordion.Body>
                                </Accordion.Item>

                            )}
                        </Accordion>
                    </Col>
                </Row>
                :
                <></>
            }
        </Container>
    );

}

// export const AccordionBasic = () => {
//     return (
//         <Accordion defaultActiveKey="0">
//             <Accordion.Item eventKey="0">
//                 <Accordion.Header className="bg-info">Accordion Item #1</Accordion.Header>
//                 <Accordion.Body>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//                     eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
//                     minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//                     aliquip ex ea commodo consequat. Duis aute irure dolor in
//                     reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
//                     pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
//                     culpa qui officia deserunt mollit anim id est laborum.
//                 </Accordion.Body>
//             </Accordion.Item>
//             <Accordion.Item eventKey="1">
//                 <Accordion.Header className="bg-info">Accordion Item #2</Accordion.Header>
//                 <Accordion.Body>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//                     eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
//                     minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//                     aliquip ex ea commodo consequat. Duis aute irure dolor in
//                     reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
//                     pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
//                     culpa qui officia deserunt mollit anim id est laborum.
//                 </Accordion.Body>
//             </Accordion.Item>
//         </Accordion>
//     );
// }
