
export const relationships = [
    {
        "id": 1,
        "name": "Estate"
    },
    {
        "id": 2,
        "name": "Business"
    },
    {
        "id": 3,
        "name": "Business Partner"
    },
    {
        "id": 4,
        "name": "Children Equally"
    },
    {
        "id": 5,
        "name": "Common Law Spouse"
    },
    {
        "id": 6,
        "name": "Father"
    },
    {
        "id": 7,
        "name": "Mother"
    },
    {
        "id": 8,
        "name": "Spouse"
    },
    {
        "id": 9,
        "name": "Sister"
    },
    {
        "id": 10,
        "name": "Brother"
    },
    {
        "id": 11,
        "name": "Trustee"
    },
    {
        "id": 12,
        "name": "NONE"
    },
    {
        "id": 13,
        "name": "Aunt"
    },
    {
        "id": 14,
        "name": "Brother-In-Law"
    },
    {
        "id": 15,
        "name": "Business Partner"
    },
    {
        "id": 16,
        "name": "Corporation"
    },
    {
        "id": 17,
        "name": "Cousin-Female"
    },
    {
        "id": 18,
        "name": "Cousin-Male"
    },
    {
        "id": 19,
        "name": "Cousin"
    },
    {
        "id": 20,
        "name": "Custodian"
    },
    {
        "id": 21,
        "name": "Custodian-Female"
    },
    {
        "id": 22,
        "name": "Custodian-Male"
    },
    {
        "id": 23,
        "name": "Daughter-In-Law"
    },
    {
        "id": 24,
        "name": "Daughter"
    },
    {
        "id": 25,
        "name": "Employee"
    },
    {
        "id": 26,
        "name": "Employer"
    },
    {
        "id": 27,
        "name": "EX-Business Partner"
    },
    {
        "id": 28,
        "name": "EX-Common Law Spouse"
    },
    {
        "id": 29,
        "name": "Executor-Female"
    },
    {
        "id": 30,
        "name": "Executor-Male"
    },
    {
        "id": 31,
        "name": "Executor"
    },
    {
        "id": 32,
        "name": "EX-Husband"
    },
    {
        "id": 33,
        "name": "EX-Wife"
    },
    {
        "id": 34,
        "name": "Father-In-Law"
    },
    {
        "id": 35,
        "name": "Fiance"
    },
    {
        "id": 36,
        "name": "Fiance-Female"
    },
    {
        "id": 37,
        "name": "Fiance-Male"
    },
    {
        "id": 38,
        "name": "Friend"
    },
    {
        "id": 39,
        "name": "Friend-Female"
    },
    {
        "id": 40,
        "name": "Friend-Male"
    },
    {
        "id": 41,
        "name": "Guardian-Female"
    },
    {
        "id": 42,
        "name": "Guardian-Male"
    },
    {
        "id": 43,
        "name": "Godchild-Female"
    },
    {
        "id": 44,
        "name": "Godchild"
    },
    {
        "id": 45,
        "name": "Godchild-Male"
    },
    {
        "id": 46,
        "name": "Grandchild"
    },
    {
        "id": 47,
        "name": "Grandfather"
    },
    {
        "id": 48,
        "name": "Grandmother"
    },
    {
        "id": 49,
        "name": "Guardian"
    },
    {
        "id": 50,
        "name": "Half-Brother"
    },
    {
        "id": 51,
        "name": "Half-Sister"
    },
    {
        "id": 52,
        "name": "Husband"
    },
    {
        "id": 53,
        "name": "Legal Heir"
    },
    {
        "id": 54,
        "name": "Mother-In-Law"
    },
    {
        "id": 55,
        "name": "Nephew"
    },
    {
        "id": 56,
        "name": "Niece"
    },
    {
        "id": 57,
        "name": "Other"
    },
    {
        "id": 58,
        "name": "Business Owner"
    },
    {
        "id": 59,
        "name": "Parent"
    },
    {
        "id": 60,
        "name": "Custodial Participant"
    },
    {
        "id": 61,
        "name": "Partner"
    },
    {
        "id": 62,
        "name": "Children Per Stirpes"
    },
    {
        "id": 63,
        "name": "Other Family Member"
    },
    {
        "id": 64,
        "name": "Self"
    },
    {
        "id": 65,
        "name": "Shareholder"
    },
    {
        "id": 66,
        "name": "Sister-In-Law"
    },
    {
        "id": 67,
        "name": "Son-In-Law"
    },
    {
        "id": 68,
        "name": "Son"
    },
    {
        "id": 69,
        "name": "Spouse-Female"
    },
    {
        "id": 70,
        "name": "Spouse-Male"
    },
    {
        "id": 71,
        "name": "Step-Brother"
    },
    {
        "id": 72,
        "name": "Step-Child Female/Male"
    },
    {
        "id": 73,
        "name": "Step-Child"
    },
    {
        "id": 74,
        "name": "Step-Father"
    },
    {
        "id": 75,
        "name": "Step-Grandchild"
    },
    {
        "id": 76,
        "name": "Step-Grandparent"
    },
    {
        "id": 77,
        "name": "Step-Mother"
    },
    {
        "id": 78,
        "name": "Step-Sister"
    },
    {
        "id": 79,
        "name": "Testamentary Trustee"
    },
    {
        "id": 80,
        "name": "Uncle"
    },
    {
        "id": 81,
        "name": "Unknown"
    },
    {
        "id": 82,
        "name": "Ward"
    },
    {
        "id": 83,
        "name": "Wife"
    },
    {
        "id": 84,
        "name": "Trustee"
    },
    {
        "id": 85,
        "name": "Spouse"
    },
    {
        "id": 86,
        "name": "Legal Guardian"
    },
    {
        "id": 87,
        "name": "Friend-Male"
    },
    {
        "id": 88,
        "name": "Friend-Female"
    },
    {
        "id": 89,
        "name": "Godfather"
    },
    {
        "id": 90,
        "name": "Godmother"
    },
    {
        "id": 91,
        "name": "Godparent"
    },
    {
        "id": 92,
        "name": "Step-Mother-In-Law"
    },
    {
        "id": 93,
        "name": "Child"
    },
    {
        "id": 94,
        "name": "Legally Adopted Child"
    }
];