import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Image,
  ProgressBar,
  Row,
} from 'react-bootstrap';
import { AiOutlineLock } from 'react-icons/ai';

import * as config from '../config.js';
import { asHyperlink } from '../libs/contact.js';
import {
  BrandingContext,
  LayoutContext,
  SessionContext,
} from '../libs/contexts.js';
import {
  BaseDesktopFaq,
  BaseDesktopPremiumBox,
} from './base-desktop.jsx';

export const CorpoDesktopLayout = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);

    return (

        <>
            <div className="content-container">
                <CorpoDesktopMenu />
                <Container className='container-main' fluid>
                    <Row>
                        <Col className="desktop-area py-3">
                            <Container fluid>
                                <Row>
                                    <Col className={ "yes" === layout.iframeVisible ? "offset-xl-1" : "offset-lg-2 offset-xl-3" }>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    <CorpoDesktopHeader />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Card className='card-outlet'>
                                                        <Card.Body>
                                                            { "yes" === layout.faq ?
                                                                <CorpoDesktopFaq />
                                                                :
                                                                <>
                                                                    { "jump" === layout.specialView ?
                                                                        <p>We are getting your quote ready, please hold...</p>
                                                                        :
                                                                        <>
                                                                            <MyOutlet></MyOutlet>
                                                                        </>
                                                                    }</>

                                                            }
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    { "yes" !== layout.iframeVisible &&
                                        /* look into hiding the sidebar when screen is too small, if it's small should be below it */
                                        <Col className="col-lg-3 col-md-12 col-xl-3">
                                            { "jump" !== layout.specialView && !["thankyou", "opportunities", "consent", "end"].includes(layout.currentScreen) &&
                                                <CorpoDesktopSidebar />
                                            }
                                        </Col>
                                    }
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col className="px-0 mx-0">
                            { "jump" !== layout.specialView && !['policy', 'thankyou', 'opportunities'].includes(layout.currentScreen) &&
                                <>
                                    <CorpoDesktopProgressBar />
                                </>
                            }
                        </Col>
                    </Row>

                </Container>
            </div>

            { "end" !== layout.currentScreen &&
                <CorpoDesktopFooter />
            }

        </>
    )


}

export const CorpoDesktopFaq = () => {

    return (
        <>
            <BaseDesktopFaq />
        </>
    );
}

export const CorpoDesktopFooter = () => {

    const { branding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const [footerLinks, setFooterLinks] = useState([]);
    const [colourTheme, setColourTheme] = useState("light");
    const [helperView, setHelperView] = useState('buttons');

    useEffect(() => {
        let newFooterLinks = [];
        if (branding.privacyLink || branding.accessibilityLink || branding.ftcLink || branding.tcLink) {
            if (branding.privacyLink && branding.privacyLink.startsWith("https:")) {
                newFooterLinks.push({ text: "Legal/Privacy", url: branding.privacyLink });
            }
            if (branding.accessibilityLink && branding.accessibilityLink.startsWith("https:")) {
                newFooterLinks.push({ text: "Accessibility policy", url: branding.accessibilityLink });
            }
            if (branding.ftcLink && branding.ftcLink.startsWith("https:")) {
                newFooterLinks.push({ text: "Fair treatment of customers policy", url: branding.ftcLink });
            }
            if (branding.tcLink && branding.tcLink.startsWith("https:")) {
                newFooterLinks.push({ text: "Terms and conditions", url: branding.tcLink });
            }
            setFooterLinks(newFooterLinks);
        }

        if ("no" === layout.faq) {
            if (!["phone", "email"].includes(helperView)) {
                setHelperView('buttons');
            }
        }
    }, [branding, layout.faq, helperView]);

    const handleFaq = (e) => {
        if ('faq' === helperView) {
            setHelperView('buttons');
            setLayout({
                faq: "no"
            })
        } else {
            setHelperView('faq');
            setLayout({
                faq: "yes"
            })
        }
        e.preventDefault();
    }

    return (
        <>
            <Container fluid className="py-3 mt-auto">
                <Row className="footer-area">
                    <Col>
                        <Container>
                            <Row className='mt-4 mb-2'>
                                <Col className='text-center footer-secure'>
                                    <AiOutlineLock /> This connection is secure
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>
                                    { footerLinks.map((footerLink, index) => (
                                        <span key={ index }>
                                            { index > 0 &&
                                                <>&nbsp;|&nbsp;</>
                                            }
                                            <a className="footer-link" target="_blank" rel="noopener noreferrer" href={ footerLink.url }>{ footerLink.text }</a>
                                        </span>
                                    )) }
                                    <span>
                                        <>&nbsp;|&nbsp;</>
                                        <a className="footer-link" target="_blank" rel="noopener noreferrer" href="#" onClick={handleFaq}>Frequently Asked Questions</a>
                                    </span>
                                    {/* <span>
                                        <>&nbsp;|&nbsp;</>
                                        
                                        <a className="footer-link" href="#" onClick={switchColour}>Switch to {colourTheme==="light" ? "dark" : "light"} theme</a>
                                    </span> */}
                                </Col>
                            </Row>
                            <Row className='mt-1 mb-4'>
                                <Col className='text-center'>
                                    <small>
                                        This life insurance quote is not meant for residents of Quebec
                                        &nbsp;--&nbsp;
                                        <i>Cette soumission d'assurance-vie n'est pas destinée aux résidents du Québec</i>
                                    </small>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export const CorpoDesktopHeader = () => {

    return (
        <>
        </>
    );
}


export const CorpoDesktopMenu = () => {

    const { layout } = useContext(LayoutContext);
    const { session } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext);
    const [phone, setPhone] = useState('');
    useEffect(() => { console.log(branding) }, [])

    useEffect(() => {

        if (layout.phone) {
            setPhone(asHyperlink({ contactInfo: layout.phone }));
        }

    }, [layout.phone]);

    return (
        <Container fluid className="menu">
            <Row className="mt-2 mb-2">
                <Col className='text-center'>
                    <Button className="btn-menu mx-2">Wawanesa Life</Button>
                    <Button className="btn-menu-active mx-2">Instant quote</Button>
                </Col>
            </Row>
            <Row>
                <Col className="px-0 mx-0">
                    <Card className='card-top'>
                        <Card.Body>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <a target="_blank" href={ branding.website } rel="noopener noreferrer">
                                            <Image className="broker-logo" src={ config.assets + '/logo.png' } />
                                        </a>
                                    </Col>
                                    <Col className='text-end'>
                                        <DropdownButton title="Contact us">
                                            <Dropdown.Item href={ phone.href }>{ phone.text }</Dropdown.Item>
                                            <Dropdown.Item href={ `mailto:${layout.email}` }>{ layout.email }</Dropdown.Item>
                                            <Dropdown.Item href={ branding.website }>Visit our Website</Dropdown.Item>
                                        </DropdownButton>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );

}

export const CorpoDesktopProgressBar = () => {

    const { layout } = useContext(LayoutContext);
    const [progress, setProgress] = useState();
    const [color, setColor] = useState('primary');
    const [isVisible, setIsVisible] = useState(true);

    const { branding } = useContext(BrandingContext);

    useEffect(() => {

        if (layout.currentScreen && layout.screenSequence) {
            let screenCount = layout.screenSequence.length;
            let screenIndex = 0;
            for (let i = 0; i < screenCount; i++) {
                if (layout.currentScreen === layout.screenSequence[i]) {
                    screenIndex = i + 1;
                }
            }
            let screenDiv = screenIndex / (screenCount);
            screenDiv = Math.ceil(100 * screenDiv);
            screenDiv = Math.round(screenDiv / 10) * 10;
            if (screenDiv > 90) {
                screenDiv = 95;
            }
            let newProgress = screenDiv;
            setProgress(newProgress);
        }

    }, [layout.currentScreen, layout.screenSequence]);

    useEffect(() => {

        if ((layout.quoterView && "needs" === layout.quoterView) || 'policy' === layout.currentScreen || 'consent' === layout.currentScreen) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }

    }, [layout.quoterView, layout.currentScreen]);


    useEffect(() => {


        if (progress <= 10) {
            setColor('primary');
        } else {
            if (progress > 10 && progress <= 40) {
                setColor('info');
            } else {
                if (progress > 40 && progress <= 80) {
                    setColor('warning');
                } else {
                    setColor('success');
                }
            }
        }

    }, [progress]);

    return (
        <>
            { isVisible && progress && progress >= 1 &&
                <>
                    <ProgressBar className="full-square" title="If you complete the process, you will be insured in a matter of minutes" animated variant={ color } now={ progress } label={ progress >= 20 ? `${progress}%` : `${progress}%` } />
                </>

            }
        </>
    )

}

export const CorpoDesktopSidebar = () => {

    const { branding } = useContext(BrandingContext);
    const { layout } = useContext(LayoutContext);


    return (
        <Container fluid>
            <Row>
                <Col>
                    <BaseDesktopPremiumBox />
                </Col>
            </Row>
        </Container>
    );

}

