export const fields =
{
    "First_Name": {
        "field": "First_Name",
        "type": "text",
        "attribute": "first_name",
        "value": []
    },
    "Last_Name": {
        "field": "Last_Name",
        "type": "text",
        "attribute": "last_name",
        "value": []
    },
    "Name_Changed": {
        "field": "Name_Changed",
        "type": "radio",
        "attribute": "name_changed",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "PreviousName": {
        "field": "PreviousName",
        "type": "text",
        "attribute": "previous_name",
        "value": []
    },
    "Birthday": {
        "field": "Birthday",
        "type": "text",
        "attribute": "dob",
        "value": []
    },
    "BirthPlace": {
        "field": "BirthPlace",
        "type": "text",
        "attribute": [
            "province_birth",
            "country"
        ],
        "value": []
    },
    "Phone": {
        "field": "Phone",
        "type": "text",
        "attribute": "phone",
        "value": []
    },
    "Street_Address": {
        "field": "Street_Address",
        "type": "text",
        "attribute": "address1",
        "value": []
    },
    "City": {
        "field": "City",
        "type": "text",
        "attribute": "city",
        "value": []
    },
    "Province_ABBR": {
        "field": "Province_ABBR",
        "type": "text",
        "attribute": "province_birth",
        "value": []
    },
    "PostalCode": {
        "field": "PostalCode",
        "type": "text",
        "attribute": "postal_code",
        "value": []
    },
    "Email": {
        "field": "Email",
        "type": "text",
        "attribute": "email",
        "value": []
    },
    "Beneficiary_Details": {
        "field": "Beneficiary_Details",
        "type": "text",
        "attribute": "beneficiary_name",
        "value": []
    },
    "Beneficiary_Relationship": {
        "field": "Beneficiary_Relationship",
        "type": "text",
        "attribute": "beneficiary_relationship",
        "value": []
    },
    "Beneficiary_Trustee": {
        "field": "Beneficiary_Trustee",
        "type": "text",
        "attribute": "beneficiary_trustee",
        "value": []
    },
    "Contingent_Beneficiary_Details": {
        "field": "Contingent_Beneficiary_Details",
        "type": "text",
        "attribute": "beneficiary_contingent_name",
        "value": []
    },
    "Contingent_Beneficiary_Relationship": {
        "field": "Contingent_Beneficiary_Relationship",
        "type": "text",
        "attribute": "beneficiary_contingent_relationship",
        "value": []
    },
    "Contingent_Beneficiary_Trustee": {
        "field": "Contingent_Beneficiary_Trustee",
        "type": "text",
        "attribute": "beneficiary_contingent_trustee",
        "value": []
    },
    "Gender": {
        "field": "Gender",
        "type": "radio",
        "attribute": "gender",
        "value": [],
        "options": [
            "M",
            "F"
        ]
    },
    "Coverage_Amount": {
        "field": "Coverage_Amount",
        "type": "text",
        "attribute": "facevalue",
        "value": []
    },
    "DWT": {
        "field": "DWT",
        "type": "checkbox",
        "attribute": "rider",
        "value": []
    },
    "Premium": {
        "field": "Premium",
        "type": "text",
        "attribute": "premium",
        "value": []
    },
    "Payment_Frequency": {
        "field": "Payment_Frequency",
        "type": "radio",
        "attribute": "payment_frequency",
        "value": [
            "Monthly"
        ],
        "options": [
            "Monthly",
            "Annually"
        ]
    },
    "Policy_Replacement": {
        "field": "Policy_Replacement",
        "type": "radio",
        "attribute": "answer_4297",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Canadian": {
        "field": "Canadian",
        "type": "radio",
        "attribute": "answer_4298",
        "value": [
            "Yes"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "DangerousOccupation": {
        "field": "DangerousOccupation",
        "type": "radio",
        "attribute": "answer_4299",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Personal_Physician_Name": {
        "field": "Personal_Physician_Name",
        "type": "text",
        "attribute": "answer_4300",
        "value": [
            "n/a"
        ]
    },
    "Personal_Physician_Address": {
        "field": "Personal_Physician_Address",
        "type": "text",
        "attribute": "answer_4301",
        "value": [
            "n/a"
        ]
    },
    "Personal_Physician_Phone": {
        "field": "Personal_Physician_Phone",
        "type": "text",
        "attribute": "answer_4302",
        "value": [
            "n/a"
        ]
    },
    "Truthful": {
        "field": "Truthful",
        "type": "radio",
        "attribute": "answer_4303",
        "value": [
            "Yes"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Restricted": {
        "field": "Restricted",
        "type": "radio",
        "attribute": "answer_4304",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Illnesses_1": {
        "field": "Illnesses_1",
        "type": "radio",
        "attribute": "answer_4307",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Illnesses_0": {
        "field": "Illnesses_0",
        "type": "radio",
        "attribute": "answer_4306",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Illnesses_2": {
        "field": "Illnesses_2",
        "type": "radio",
        "attribute": "answer_4308",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Illnesses_3": {
        "field": "Illnesses_3",
        "type": "radio",
        "attribute": "answer_4309",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Illnesses_4": {
        "field": "Illnesses_4",
        "type": "radio",
        "attribute": "answer_4310",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Illnesses_5": {
        "field": "Illnesses_5",
        "type": "radio",
        "attribute": "answer_4311",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Illnesses_6": {
        "field": "Illnesses_6",
        "type": "radio",
        "attribute": "answer_4312",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Declined": {
        "field": "Declined",
        "type": "radio",
        "attribute": "answer_4314",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Criminal_Offense": {
        "field": "Criminal_Offense",
        "type": "radio",
        "attribute": "answer_4315",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Disabled": {
        "field": "Disabled",
        "type": "radio",
        "attribute": "answer_4317",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Illicit_Drugs": {
        "field": "Illicit_Drugs",
        "type": "radio",
        "attribute": "answer_4318",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Substance_Abuse": {
        "field": "Substance_Abuse",
        "type": "radio",
        "attribute": "answer_4318",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Height": {
        "field": "Height",
        "type": "text",
        "attribute": "height",
        "value": []
    },
    "Weight": {
        "field": "Weight",
        "type": "text",
        "attribute": "weight",
        "value": []
    },
    "Colonoscopy": {
        "field": "Colonoscopy",
        "type": "radio",
        "attribute": "answer_4321",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Colonoscopy_Hemorrhoids": {
        "field": "Colonoscopy_Hemorrhoids",
        "type": "radio",
        "attribute": "answer_4323",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Colonoscopy_Other": {
        "field": "Colonoscopy_Other",
        "type": "radio",
        "attribute": "answer_4328",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Colonoscopy_Followup_No": {
        "field": "Colonoscopy_Followup_No",
        "type": "radio",
        "attribute": "answer_4327",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Colonoscopy_Followup_Dont_Know": {
        "field": "Colonoscopy_Followup_Dont_Know",
        "type": "radio",
        "attribute": "answer_4329",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Colonoscopy_Followup_5_10": {
        "field": "Colonoscopy_Followup_5_10",
        "type": "radio",
        "attribute": "",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Colonoscopy_Followup_In_3": {
        "field": "Colonoscopy_Followup_In_3",
        "type": "radio",
        "attribute": "answer_4330",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Colonoscopy_Followup_Within_3": {
        "field": "Colonoscopy_Followup_Within_3",
        "type": "radio",
        "attribute": "answer_4331",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Colonoscopy_IBS": {
        "field": "Colonoscopy_IBS",
        "type": "radio",
        "attribute": "answer_4324",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "MRI": {
        "field": "MRI",
        "type": "radio",
        "attribute": "answer_4332",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Mammogram": {
        "field": "Mammogram",
        "type": "radio",
        "attribute": "answer_4333",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "EKG": {
        "field": "EKG",
        "type": "radio",
        "attribute": "answer_4334",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Biopsy": {
        "field": "Biopsy",
        "type": "radio",
        "attribute": "answer_4335",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Depression": {
        "field": "Depression",
        "type": "radio",
        "attribute": "answer_4337",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Depression_When": {
        "field": "Depression_When",
        "type": "radio",
        "attribute": "answer_4338",
        "value": [],
        "options": [
            "Within",
            "More"
        ]
    },
    "Depression_Followed_By": {
        "field": "Depression_Followed_By",
        "type": "radio",
        "attribute": "answer_4339",
        "value": [],
        "options": [
            "Specialist",
            "Family"
        ]
    },
    "Depression_Time_Off": {
        "field": "Depression_Time_Off",
        "type": "radio",
        "attribute": "answer_4340",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Depression_Hospitalized": {
        "field": "Depression_Hospitalized",
        "type": "radio",
        "attribute": "answer_4341",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Depression_Suicidal": {
        "field": "Depression_Suicidal",
        "type": "radio",
        "attribute": "answer_4311",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Depression_Increased_Dose": {
        "field": "Depression_Increased_Dose",
        "type": "radio",
        "attribute": "answer_4343",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Disability_Benefits": {
        "field": "Disability_Benefits",
        "type": "radio",
        "attribute": "answer_4344",
        "value": [
            "No"
        ],
        "options": [
            "Yes",
            "No"
        ]
    },
    "License_Suspended": {
        "field": "License_Suspended",
        "type": "radio",
        "attribute": "answer_4345",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Impaired_Driving": {
        "field": "Impaired_Driving",
        "type": "radio",
        "attribute": "answer_4346",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Driving_Violations": {
        "field": "Driving_Violations",
        "type": "radio",
        "attribute": "answer_4347",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Cigars": {
        "field": "Cigars",
        "type": "radio",
        "attribute": "answer_4348",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Smoker_Type": {
        "field": "Smoker_Type",
        "type": "radio",
        "attribute": "",
        "value": [],
        "options": [
            "5",
            "4",
            "2",
            "1",
            "3"
        ]
    },
    "Weekly_Alcohol": {
        "field": "Weekly_Alcohol",
        "type": "radio",
        "attribute": "answer_4350",
        "value": [],
        "options": [
            "Over_30",
            "25_29",
            "15_24",
            "Less_15",
            "None"
        ]
    },
    "Marijuana_Consumption": {
        "field": "Marijuana_Consumption",
        "type": "radio",
        "attribute": "answer_4351",
        "value": [],
        "options": [
            "Over_1_Day",
            "r_4_6_Week",
            "Less_3_Week",
            "None"
        ]
    },
    "Undiagnosed": {
        "field": "Undiagnosed",
        "type": "radio",
        "attribute": "answer_4352",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Unconsulted": {
        "field": "Unconsulted",
        "type": "radio",
        "attribute": "answer_4353",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Extreme_Situations": {
        "field": "Extreme_Situations",
        "type": "radio",
        "attribute": "answer_4354",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Travel": {
        "field": "Travel",
        "type": "radio",
        "attribute": "answer_4355",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Account_Owner_Name": {
        "field": "Account_Owner_Name",
        "type": "text",
        "attribute": [
            "first_name",
            "last_name"
        ],
        "value": []
    },
    "Account_Owner_Address": {
        "field": "Account_Owner_Address",
        "type": "text",
        "attribute": "address",
        "value": []
    },
    "Account_Owner_Phone": {
        "field": "Account_Owner_Phone",
        "type": "text",
        "attribute": "phone",
        "value": []
    },
    "Transit": {
        "field": "Transit",
        "type": "text",
        "attribute": "payment_transit",
        "value": []
    },
    "Financial": {
        "field": "Financial",
        "type": "text",
        "attribute": "payment_bank",
        "value": []
    },
    "Account": {
        "field": "Account",
        "type": "text",
        "attribute": "payment_account",
        "value": []
    },
    "Branch": {
        "field": "Branch",
        "type": "text",
        "attribute": "payment_branch",
        "value": []
    },
    "Policy_Date": {
        "field": "Policy_Date",
        "type": "radio",
        "attribute": "date",
        "value": [],
        "options": [
            "Yes",
            "No"
        ]
    },
    "Withdrawal_Day": {
        "field": "Withdrawal_Day",
        "type": "text",
        "attribute": "payment_day",
        "value": []
    },
    "Life_Insured_Signature": {
        "field": "Life_Insured_Signature",
        "type": "text",
        "attribute": "",
        "value": []
    },
    "Account_Holder_Signature": {
        "field": "Account_Holder_Signature",
        "type": "text",
        "attribute": "",
        "value": []
    },
    "Date_Signed": {
        "field": "Date_Signed",
        "type": "text",
        "attribute": "date",
        "value": []
    },
    "SignedAt": {
        "field": "SignedAt",
        "type": "text",
        "attribute": "geo_city",
        "value": []
    },
    "SignedProvince": {
        "field": "SignedProvince",
        "type": "text",
        "attribute": "geo_province",
        "value": []
    },
    "PlanName": {
        "field": "PlanName",
        "type": "text",
        "attribute": "plan_name",
        "value": []
    }
};

export const jurisdictions =
    { "provinces": [{ "name": "Alberta", "big_city": "Calgary", "id": "1", "countrycode": "CAN", "statecode": "AB", "country_id": "2", "state_id": "101" }, { "name": "British Columbia", "big_city": "Vancouver", "id": "2", "countrycode": "CAN", "statecode": "BC", "country_id": "2", "state_id": "102" }, { "name": "Manitoba", "big_city": "Winnipeg", "id": "3", "countrycode": "CAN", "statecode": "MB", "country_id": "2", "state_id": "103" }, { "name": "New Brunswick", "big_city": "Moncton", "id": "4", "countrycode": "CAN", "statecode": "NB", "country_id": "2", "state_id": "104" }, { "name": "Newfoundland and Labrador", "big_city": "St. John's", "id": "5", "countrycode": "CAN", "statecode": "NL", "country_id": "2", "state_id": "105" }, { "name": "Nova Scotia", "big_city": "Halifax", "id": "6", "countrycode": "CAN", "statecode": "NS", "country_id": "2", "state_id": "107" }, { "name": "Northwest Territories", "big_city": "Yellowknife", "id": "7", "countrycode": "CAN", "statecode": "NT", "country_id": "2", "state_id": "106" }, { "name": "Ontario", "big_city": "Toronto", "id": "8", "countrycode": "CAN", "statecode": "ON", "country_id": "2", "state_id": "108" }, { "name": "Prince Edward Island", "big_city": "Charlottetown", "id": "9", "countrycode": "CAN", "statecode": "PE", "country_id": "2", "state_id": "109" }, { "name": "Quebec", "big_city": "Monteal", "id": "10", "countrycode": "CAN", "statecode": "QC", "country_id": "2", "state_id": "110" }, { "name": "Saskatchewan", "big_city": "Saskatoon", "id": "11", "countrycode": "CAN", "statecode": "SK", "country_id": "2", "state_id": "111" }, { "name": "Yukon", "big_city": "Whitehorse", "id": "12", "countrycode": "CAN", "statecode": "YT", "country_id": "2", "state_id": "112" }, { "name": "Nunavut", "big_city": "Iqaluit", "id": "310", "countrycode": "CAN", "statecode": "NU", "country_id": "2", "state_id": "113" }] };

export const relationships =
    [
        {
            "id": 1,
            "name": "Estate"
        },
        {
            "id": 2,
            "name": "Business"
        },
        {
            "id": 3,
            "name": "Business Partner"
        },
        {
            "id": 4,
            "name": "Children Equally"
        },
        {
            "id": 5,
            "name": "Common Law Spouse"
        },
        {
            "id": 6,
            "name": "Father"
        },
        {
            "id": 7,
            "name": "Mother"
        },
        {
            "id": 8,
            "name": "Spouse"
        },
        {
            "id": 9,
            "name": "Sister"
        },
        {
            "id": 10,
            "name": "Brother"
        },
        {
            "id": 11,
            "name": "Trustee"
        },
        {
            "id": 12,
            "name": "NONE"
        },
        {
            "id": 13,
            "name": "Aunt"
        },
        {
            "id": 14,
            "name": "Brother-In-Law"
        },
        {
            "id": 15,
            "name": "Business Partner"
        },
        {
            "id": 16,
            "name": "Corporation"
        },
        {
            "id": 17,
            "name": "Cousin-Female"
        },
        {
            "id": 18,
            "name": "Cousin-Male"
        },
        {
            "id": 19,
            "name": "Cousin"
        },
        {
            "id": 20,
            "name": "Custodian"
        },
        {
            "id": 21,
            "name": "Custodian-Female"
        },
        {
            "id": 22,
            "name": "Custodian-Male"
        },
        {
            "id": 23,
            "name": "Daughter-In-Law"
        },
        {
            "id": 24,
            "name": "Daughter"
        },
        {
            "id": 25,
            "name": "Employee"
        },
        {
            "id": 26,
            "name": "Employer"
        },
        {
            "id": 27,
            "name": "EX-Business Partner"
        },
        {
            "id": 28,
            "name": "EX-Common Law Spouse"
        },
        {
            "id": 29,
            "name": "Executor-Female"
        },
        {
            "id": 30,
            "name": "Executor-Male"
        },
        {
            "id": 31,
            "name": "Executor"
        },
        {
            "id": 32,
            "name": "EX-Husband"
        },
        {
            "id": 33,
            "name": "EX-Wife"
        },
        {
            "id": 34,
            "name": "Father-In-Law"
        },
        {
            "id": 35,
            "name": "Fiance"
        },
        {
            "id": 36,
            "name": "Fiance-Female"
        },
        {
            "id": 37,
            "name": "Fiance-Male"
        },
        {
            "id": 38,
            "name": "Friend"
        },
        {
            "id": 39,
            "name": "Friend-Female"
        },
        {
            "id": 40,
            "name": "Friend-Male"
        },
        {
            "id": 41,
            "name": "Guardian-Female"
        },
        {
            "id": 42,
            "name": "Guardian-Male"
        },
        {
            "id": 43,
            "name": "Godchild-Female"
        },
        {
            "id": 44,
            "name": "Godchild"
        },
        {
            "id": 45,
            "name": "Godchild-Male"
        },
        {
            "id": 46,
            "name": "Grandchild"
        },
        {
            "id": 47,
            "name": "Grandfather"
        },
        {
            "id": 48,
            "name": "Grandmother"
        },
        {
            "id": 49,
            "name": "Guardian"
        },
        {
            "id": 50,
            "name": "Half-Brother"
        },
        {
            "id": 51,
            "name": "Half-Sister"
        },
        {
            "id": 52,
            "name": "Husband"
        },
        {
            "id": 53,
            "name": "Legal Heir"
        },
        {
            "id": 54,
            "name": "Mother-In-Law"
        },
        {
            "id": 55,
            "name": "Nephew"
        },
        {
            "id": 56,
            "name": "Niece"
        },
        {
            "id": 57,
            "name": "Other"
        },
        {
            "id": 58,
            "name": "Business Owner"
        },
        {
            "id": 59,
            "name": "Parent"
        },
        {
            "id": 60,
            "name": "Custodial Participant"
        },
        {
            "id": 61,
            "name": "Partner"
        },
        {
            "id": 62,
            "name": "Children Per Stirpes"
        },
        {
            "id": 63,
            "name": "Other Family Member"
        },
        {
            "id": 64,
            "name": "Self"
        },
        {
            "id": 65,
            "name": "Shareholder"
        },
        {
            "id": 66,
            "name": "Sister-In-Law"
        },
        {
            "id": 67,
            "name": "Son-In-Law"
        },
        {
            "id": 68,
            "name": "Son"
        },
        {
            "id": 69,
            "name": "Spouse-Female"
        },
        {
            "id": 70,
            "name": "Spouse-Male"
        },
        {
            "id": 71,
            "name": "Step-Brother"
        },
        {
            "id": 72,
            "name": "Step-Child Female/Male"
        },
        {
            "id": 73,
            "name": "Step-Child"
        },
        {
            "id": 74,
            "name": "Step-Father"
        },
        {
            "id": 75,
            "name": "Step-Grandchild"
        },
        {
            "id": 76,
            "name": "Step-Grandparent"
        },
        {
            "id": 77,
            "name": "Step-Mother"
        },
        {
            "id": 78,
            "name": "Step-Sister"
        },
        {
            "id": 79,
            "name": "Testamentary Trustee"
        },
        {
            "id": 80,
            "name": "Uncle"
        },
        {
            "id": 81,
            "name": "Unknown"
        },
        {
            "id": 82,
            "name": "Ward"
        },
        {
            "id": 83,
            "name": "Wife"
        },
        {
            "id": 84,
            "name": "Trustee"
        },
        {
            "id": 85,
            "name": "Spouse"
        },
        {
            "id": 86,
            "name": "Legal Guardian"
        },
        {
            "id": 87,
            "name": "Friend-Male"
        },
        {
            "id": 88,
            "name": "Friend-Female"
        },
        {
            "id": 89,
            "name": "Godfather"
        },
        {
            "id": 90,
            "name": "Godmother"
        },
        {
            "id": 91,
            "name": "Godparent"
        },
        {
            "id": 92,
            "name": "Step-Mother-In-Law"
        },
        {
            "id": 93,
            "name": "Child"
        },
        {
            "id": 94,
            "name": "Legally Adopted Child"
        }
    ];

export const weights =
    [
        {
            "value": 4.08,
            "ft": "4'8",
            "cm": 142,
            "lb_low": 76,
            "lb_high": 166,
            "kg_low": 34,
            "kg_high": 75
        },
        {
            "value": 4.09,
            "ft": "4'9",
            "cm": 145,
            "lb_low": 79,
            "lb_high": 173,
            "kg_low": 36,
            "kg_high": 78
        },
        {
            "value": 4.10,
            "ft": "4'10",
            "cm": 147,
            "lb_low": 81,
            "lb_high": 178,
            "kg_low": 37,
            "kg_high": 81
        },
        {
            "value": 4.11,
            "ft": "4'11",
            "cm": 150,
            "lb_low": 85,
            "lb_high": 185,
            "kg_low": 39,
            "kg_high": 84
        },
        {
            "value": 4.12,
            "ft": "5'0",
            "cm": 152,
            "lb_low": 87,
            "lb_high": 190,
            "kg_low": 39,
            "kg_high": 86
        },
        {
            "value": 5.01,
            "ft": "5'1",
            "cm": 155,
            "lb_low": 91,
            "lb_high": 198,
            "kg_low": 41,
            "kg_high": 90
        },
        {
            "value": 5.02,
            "ft": "5'2",
            "cm": 157,
            "lb_low": 93,
            "lb_high": 204,
            "kg_low": 42,
            "kg_high": 93
        },
        {
            "value": 5.03,
            "ft": "5'3",
            "cm": 160,
            "lb_low": 96,
            "lb_high": 211,
            "kg_low": 44,
            "kg_high": 96
        },
        {
            "value": 5.04,
            "ft": "5'4",
            "cm": 163,
            "lb_low": 100,
            "lb_high": 219,
            "kg_low": 45,
            "kg_high": 99
        },
        {
            "value": 5.05,
            "ft": "5'5",
            "cm": 165,
            "lb_low": 102,
            "lb_high": 225,
            "kg_low": 46,
            "kg_high": 102
        },
        {
            "value": 5.06,
            "ft": "5'6",
            "cm": 168,
            "lb_low": 106,
            "lb_high": 233,
            "kg_low": 48,
            "kg_high": 106
        },
        {
            "value": 5.07,
            "ft": "5'7",
            "cm": 170,
            "lb_low": 109,
            "lb_high": 238,
            "kg_low": 49,
            "kg_high": 108
        },
        {
            "value": 5.08,
            "ft": "5'8",
            "cm": 173,
            "lb_low": 113,
            "lb_high": 247,
            "kg_low": 51,
            "kg_high": 112
        },
        {
            "value": 5.09,
            "ft": "5'9",
            "cm": 175,
            "lb_low": 115,
            "lb_high": 253,
            "kg_low": 52,
            "kg_high": 115
        },
        {
            "value": 5.10,
            "ft": "5'10",
            "cm": 178,
            "lb_low": 119,
            "lb_high": 261,
            "kg_low": 54,
            "kg_high": 118
        },
        {
            "value": 5.11,
            "ft": "5'11",
            "cm": 180,
            "lb_low": 122,
            "lb_high": 267,
            "kg_low": 55,
            "kg_high": 121
        },
        {
            "value": 5.12,
            "ft": "6'0",
            "cm": 183,
            "lb_low": 126,
            "lb_high": 276,
            "kg_low": 57,
            "kg_high": 125
        },
        {
            "value": 6.01,
            "ft": "6'1",
            "cm": 185,
            "lb_low": 129,
            "lb_high": 283,
            "kg_low": 59,
            "kg_high": 128
        },
        {
            "value": 6.02,
            "ft": "6'2",
            "cm": 188,
            "lb_low": 133,
            "lb_high": 292,
            "kg_low": 60,
            "kg_high": 132
        },
        {
            "value": 6.03,
            "ft": "6'3",
            "cm": 191,
            "lb_low": 137,
            "lb_high": 301,
            "kg_low": 62,
            "kg_high": 137
        },
        {
            "value": 6.04,
            "ft": "6'4",
            "cm": 193,
            "lb_low": 140,
            "lb_high": 307,
            "kg_low": 64,
            "kg_high": 139
        },
        {
            "value": 6.05,
            "ft": "6'5",
            "cm": 196,
            "lb_low": 144,
            "lb_high": 317,
            "kg_low": 65,
            "kg_high": 144
        },
        {
            "value": 6.06,
            "ft": "6'6",
            "cm": 198,
            "lb_low": 147,
            "lb_high": 324,
            "kg_low": 67,
            "kg_high": 147
        },
        {
            "value": 6.07,
            "ft": "6'7",
            "cm": 201,
            "lb_low": 152,
            "lb_high": 333,
            "kg_low": 69,
            "kg_high": 151
        },
        {
            "value": 6.08,
            "ft": "6'8",
            "cm": 203,
            "lb_low": 155,
            "lb_high": 340,
            "kg_low": 70,
            "kg_high": 154
        },
        {
            "value": 6.09,
            "ft": "6'9",
            "cm": 206,
            "lb_low": 159,
            "lb_high": 350,
            "kg_low": 72,
            "kg_high": 159
        },
        {
            "value": 6.10,
            "ft": "6'10",
            "cm": 208,
            "lb_low": 163,
            "lb_high": 357,
            "kg_low": 74,
            "kg_high": 162
        },
        {
            "value": 6.11,
            "ft": "6'11",
            "cm": 211,
            "lb_low": 167,
            "lb_high": 368,
            "kg_low": 76,
            "kg_high": 167
        },
        {
            "value": 6.12,
            "ft": "7'0",
            "cm": 213,
            "lb_low": 170,
            "lb_high": 375,
            "kg_low": 77,
            "kg_high": 170
        }
    ];
